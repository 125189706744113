import { Injectable } from "@angular/core";
import { BehaviorSubject, throwError } from "rxjs";
import { tap, catchError, map } from "rxjs/operators";
import { ApiService } from "src/app/common/services/api.service";

@Injectable({
  providedIn: "root",
})
export class TagService {
  constructor(private api: ApiService) {}

  create(name: String) {
    const data = {
      name: name,
    };
    return this.api.post(this.api.url("/content/tag/"), data);
  }

  search(query: String, page: number = 1, limit = 10) {
    let params: any = {
      search: query,
      page: page,
      limit: limit,
    };

    return this.api
      .get(this.api.url("/content/tag/"), { params: params })
      .pipe(map((data) => data.results.map((tag) => tag.name)));
  }
}
