<ng-container>
  <div class="modal-backdrop">
    <div class="modal modal--small">
      <div class="modal__close" (click)="close()">
        <i class="fas fa-times"></i>
      </div>
      <div class="dialog">
        <h3>Add a Payment Method</h3>

        <app-billing-form
          [showCancel]="true"
          (onSuccess)="handleConfirm($event)"
          (onCancel)="close()"
        ></app-billing-form>
      </div>
    </div>
  </div>
</ng-container>
