import { NgModule } from "@angular/core";
import { UserRoutingModule, routedComponents } from "./user-routing.module";
import { SharedModule } from "../shared/shared.module";
import { SettingsFormComponent } from './components/settings-form/settings-form.component';
import { BillingScreenComponent } from './screens/billing-screen/billing-screen.component';

@NgModule({
  declarations: [...routedComponents, SettingsFormComponent, BillingScreenComponent],
  imports: [SharedModule, UserRoutingModule],
})
export class UserModule {}
