import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable } from "rxjs";
import { share } from "rxjs/operators";

import { environment } from "src/environments/environment";

import { StorageService } from "./storage.service";
import { StorageItem } from "../models/storage.model";
import { Token } from "../../auth/models/token.model";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private domain: string = environment.apiDomain;
  private accessToken: Token;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.accessToken = storageService.getItem(StorageItem.ACCESS_TOKEN);
  }

  /**
   * Joins to the base of the API URL.
   *
   * @param {string} str
   * @returns {string}
   * @memberof ApiService
   */
  public url(str: string): string {
    return this.domain + str;
  }

  /**
   * GET Request
   *
   * @param {string} url
   * @param {any} [options]
   * @returns {Observable<Response>}
   * @memberof ApiService
   */
  public get(url: string, options?: any): Observable<any> {
    url = url.replace(/\/?$/, "/");
    let headers = new HttpHeaders();

    if (options && options.params) {
      Object.keys(options.params).map((key) => {
        if (
          options.params[key] === null ||
          options.params[key] === undefined ||
          options.params[key] === ""
        ) {
          delete options.params[key];
        }
      });
    }

    options = this.mergeOptions(options, headers);
    return this.http.get(url, options).pipe(share());
  }

  /**
   * POST Request
   *
   * @param {string} url
   * @param {*} data
   * @param {any} [options]
   * @returns {Observable<Response>}
   * @memberof ApiService
   */
  public post(
    url: string,
    data?: any,
    options?: any,
    anonymous: boolean = false
  ): Observable<any> {
    url = url.replace(/\/?$/, "/");
    let headers = new HttpHeaders();

    if (anonymous) {
      headers.set("anonymous", "yes");
    }

    options = this.mergeOptions(options, headers);
    return this.http.post(url, data, options).pipe(share());
  }

  /**
   * PUT Request
   *
   * @param {string} url
   * @param {*} data
   * @param {any} [options]
   * @returns {Observable<Response>}
   * @memberof ApiService
   */
  public put(url: string, data: any, options?: any): Observable<any> {
    url = url.replace(/\/?$/, "/");
    let headers = new HttpHeaders();

    options = this.mergeOptions(options, headers);
    return this.http.put(url, data, options).pipe(share());
  }

  /**
   * PATCH request
   *
   * @param {string} url
   * @param {*} data
   * @param {any} [options]
   * @returns {Observable<Response>}
   * @memberof ApiService
   */
  public patch(url: string, data: any, options?: any): Observable<any> {
    url = url.replace(/\/?$/, "/");
    let headers = new HttpHeaders();

    options = this.mergeOptions(options, headers);
    return this.http.patch(url, data, options).pipe(share());
  }

  /**
   * DELETE request.
   *
   * @param {string} url
   * @param {any} [options]
   * @returns {Observable<Response>}
   * @memberof ApiService
   */
  public delete(url: string, data?: any, options?: any): Observable<any> {
    url = url.replace(/\/?$/, "/");
    let headers = new HttpHeaders();

    options = this.mergeOptions(options, headers);
    return this.http.delete(url, options).pipe(share());
  }

  /**
   * Merge options with headers.
   *
   * @private
   * @param {any} options
   * @param {Headers} headers
   * @returns {any}
   * @memberof ApiService
   */
  private mergeOptions(options: any, headers: HttpHeaders): any {
    if (options) {
      if (options.headers) {
        headers["Content-Type"] = options.headers["Content-Type"];
      } else {
        headers["Content-Type"] = "application/json";
      }
      options = Object.assign(options, { headers: headers });
    } else {
      options = Object.assign({}, { headers: headers });
    }

    return options;
  }
}
