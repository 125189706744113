<ng-container>
  <div class="modal-backdrop">
    <div class="modal modal--small">
      <div class="modal__close" (click)="close()">
        <i class="fas fa-times"></i>
      </div>
      <div class="dialog">
        <h3>Account Required</h3>
        <p>Please register or login to continue.</p>

        <nav class="nav nav-pills flex-column flex-sm-row">
          <button
            class="flex-sm-fill text-sm-center nav-link"
            [ngClass]="{ active: !showingLogin }"
            (click)="showingLogin = false"
          >
            Register
          </button>
          <button
            class="flex-sm-fill text-sm-center nav-link"
            [ngClass]="{ active: showingLogin }"
            (click)="showingLogin = true"
          >
            Login
          </button>
        </nav>

        <ng-container *ngIf="showingLogin">
          <app-login-form
            (onSuccess)="handleSuccess($event)"
            (onError)="handleError()"
            [forgotPasswordNewTab]="true"
          ></app-login-form>
        </ng-container>

        <ng-container *ngIf="!showingLogin">
          <app-register-form
            (onSuccess)="handleSuccess($event)"
            (onError)="handleError()"
          ></app-register-form>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
