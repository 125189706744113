import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TagService } from "../../services/tag.service";
// import { TagService } from "src/app/services/tag.service";
// import { Tag } from "src/app/models/tag.model";

@Component({
  selector: "app-tag-editor",
  templateUrl: "./tag-editor.component.html",
  styleUrls: ["./tag-editor.component.scss"],
})
export class TagEditorComponent implements OnInit {
  @Input() tags: any[] = [];
  public results: any[] = [];
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() initialTags;
  @Input() placeholder: string = "Add a tag...";
  @Input() addTagText: string = "Add another tag...";

  constructor(private tagService: TagService) {}

  ngOnInit() {
    if (this.initialTags) {
      this.tags = this.initialTags;
    }
  }

  onItemAdded(data) {
    if (!data?.value) {
      return;
    }
    this.tagService.create(data.value).toPromise();
    this.onChange.emit(
      this.tags.map((t) => {
        if (typeof t === "string") {
          return t;
        }
        return t.value;
      })
    );
  }

  handleTextChange(text: String) {
    if (!text || !text.length) {
      this.results = [];
      return;
    }

    this.tagService.search(text).subscribe(
      (data) => {
        this.results = data;
      },
      (err) => {
        this.results = [];
      }
    );
  }
}
