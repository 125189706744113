import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { PaginatedResponse } from "src/app/common/models/paginated-response.model";
import { Post, PostQueryType } from "src/app/content/post/models/post.model";
import { PostService } from "src/app/content/post/services/post.service";
import { User } from "src/app/user/models/user.model";

@Component({
  selector: "app-post-list",
  templateUrl: "./post-list.component.html",
  styleUrls: ["./post-list.component.scss"],
})
export class PostListComponent implements OnInit {
  @Input() queryType: PostQueryType = PostQueryType.Public;
  @Input() user: User;
  public posts$: Observable<PaginatedResponse<Post>>;

  public limit: number = 3;
  public page: number;

  constructor(private postService: PostService) {}

  ngOnInit(): void {
    this.query();
  }

  private query(page: number = 1) {
    this.page = page;
    switch (this.queryType) {
      case PostQueryType.User:
        this.posts$ = this.postService.user(this.user, this.page, this.limit);
        break;
      case PostQueryType.Me:
        this.posts$ = this.postService.me(this.page, this.limit);
        break;
      case PostQueryType.Public:
      default:
        this.posts$ = this.postService.list(this.page, this.limit);
        break;
    }
  }

  onPageChange(page: number) {
    this.query(page);
  }
}
