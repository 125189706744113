import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Asset, AssetStatus } from "../../models/asset.model";
import { AssetService } from "../../services/asset.service";

@Component({
  selector: "app-asset-detail-screen",
  templateUrl: "./asset-detail-screen.component.html",
  styleUrls: ["./asset-detail-screen.component.scss"],
})
export class AssetDetailScreenComponent implements OnInit {
  public asset: Asset;

  public showing3d: boolean = false;
  public showingToning: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private assetService: AssetService
  ) {}

  ngOnInit(): void {
    this.retrieve();
  }

  retrieve() {
    const { uuid } = this.route.snapshot.params;

    this.assetService
      .retrieve(uuid)
      .toPromise()
      .then((asset) => {
        this.asset = asset;
        if (this.asset.status != AssetStatus.COMPLETE) {
          setTimeout(() => {
            this.retrieve();
          }, 3000);
        }
      });
  }

  async stitch() {
    await this.assetService.stitch(this.asset.uuid).toPromise();
  }
}
