<div class="row" *ngIf="asset">
  <div class="col-12">
    <h3>{{ asset.title }}</h3>

    <hr />

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="d-flex justify-content-between">
          <div class="badge">Status:</div>
          <div class="text-uppercase badge badge-success">
            {{ asset.status }}
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div class="badge">Created:</div>
          <div class="badge">{{ asset.created_at | date }}</div>
        </div>
        <hr />

        <div class="d-flex justify-content-between align-items-center">
          <div class="badge">Actions:</div>
          <div class="btn-group">
            <a
              [routerLink]="'/assets/edit/' + asset.uuid"
              class="btn btn-secondary btn-sm"
              >Edit</a
            >
            <button class="btn btn-primary btn-sm" (click)="stitch()">
              Re Stitch
            </button>
          </div>
        </div>
        <hr />

        <div *ngIf="asset.output_image" class="image">
          <h5>Input Image:</h5>
          <img [src]="asset.output_image.url" width="100%" />
        </div>
      </div>

      <div class="col-12 col-md-6">
        <h5>HDR File:</h5>
        <div class="output" *ngIf="asset.output_image; else pending">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              [value]="asset.output_hdr.url"
            />
          </div>
          <a
            class="btn btn-primary"
            download
            [href]="asset.output_hdr.url"
            target="_blank"
            ><i class="fa fa-download mr-1"></i>Download
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="col col-12 py-2" *ngIf="asset.output_hdr">
    <h3>3D Preview</h3>
    <button
      *ngIf="!showing3d"
      class="btn btn-secondary"
      (click)="showing3d = true"
    >
      <i class="fa fa-chevron-down mr-1"></i>

      Show
    </button>
    <button
      *ngIf="showing3d"
      class="btn btn-secondary"
      (click)="showing3d = false"
    >
      <i class="fa fa-chevron-up mr-1"></i>
      Hide
    </button>
    <app-hdr-preview
      *ngIf="showing3d"
      [showControls]="false"
      [fileUrl]="asset.output_hdr.url"
    ></app-hdr-preview>
  </div>
  <!-- <div class="col col-12 py-2" *ngIf="asset.output_image">
    <h3>Toning</h3>
    <button
      *ngIf="!showingToning"
      class="btn btn-secondary"
      (click)="showingToning = true"
    >
      <i class="fa fa-chevron-down mr-1"></i>

      Show
    </button>
    <button
      *ngIf="showingToning"
      class="btn btn-secondary"
      (click)="showingToning = false"
    >
      <i class="fa fa-chevron-up mr-1"></i>
      Hide
    </button>
    <app-hdr-toning-preview
      *ngIf="showingToning"
      [asset]="asset"
    ></app-hdr-toning-preview>
  </div> -->
</div>

<ng-template #pending>
  <i class="mr-1 fa fa-spin fa-spinner"></i>
  <span>Processing</span>
</ng-template>
