import { FileAsset } from "src/app/media/models/asset.model";

export enum AssetStatus {
  DRAFT = "draft",
  INITIALIZED = "initialized",
  PENDING_STITCHING = "pending_stitching",
  STITCHED = "stitched",
  PENDING_HDR = "pending_hdr",
  COMPLETE = "complete",
  REMOVED = "removed",
}

export enum CaptureMode {
  SingleImage,
  Stitching,
  StreetView,
}

export interface Asset {
  id?: number;
  uuid?: string;
  title?: String;
  status?: AssetStatus;
  output_image?: FileAsset;
  output_hdr?: FileAsset;
  thumbnail_image?: FileAsset;
  created_at?: Date;
}
