<tag-input
  [(ngModel)]="tags"
  [placeholder]="addTagText"
  [secondaryPlaceholder]="placeholder"
  (onBlur)="onItemAdded($event)"
  [animationDuration]="{ enter: '0ms', leave: '0ms' }"
  (onRemove)="onItemAdded($event)"
  (onAdd)="onItemAdded($event)"
  separatorKeyCodes="[32]"
  separatorKeys="[',']"
  addOnBlur="true"
  onTextChangeDebounce="150"
  (onTextChange)="handleTextChange($event)"
>
  <tag-input-dropdown [autocompleteItems]="results"></tag-input-dropdown>
</tag-input>
