import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "src/app/shared/services/toast.service";
import { ForgotPasswordFormService } from "../../services/forgot-password-form.service";

@Component({
  selector: "app-forgot-password-form",
  templateUrl: "./forgot-password-form.component.html",
  styleUrls: ["./forgot-password-form.component.scss"],
})
export class ForgotPasswordFormComponent implements OnInit {
  public ready = false;
  public completed = false;

  constructor(
    private formService: ForgotPasswordFormService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit() {
    const { uuid, token } = this.route.snapshot.params;

    if (uuid && token) {
      const valid = await this.formService.validatePasswordReset(uuid, token);
      if (valid) {
        this.formService.buildConfirmationForm();
      } else {
        this.toastService.error();
        this.router.navigate(["/"]);
      }
    } else {
      this.formService.buildForm();
      this.ready = true;
    }
  }

  public get form() {
    return this.formService.form;
  }

  public get confirmationForm() {
    return this.formService.confirmationForm;
  }

  public async submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const success = await this.formService.requestPasswordReset();
    if (success) {
      this.completed = true;
      this.toastService.success("Password reset email sent.");
    } else {
      this.toastService.error();
    }
  }

  public async submitConfirmation() {
    const { uuid, token } = this.route.snapshot.params;

    const success = await this.formService.confirmPasswordReset(uuid, token);
    if (success) {
      this.completed = true;
      this.toastService.success("Password updated.");
      this.router.navigate(["/auth/login"]);
    } else {
      this.toastService.error();
    }
  }
}
