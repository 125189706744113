import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Post } from "src/app/content/post/models/post.model";
import { UserService } from "src/app/user/services/user.service";

@Component({
  selector: "app-post-context-menu-button",
  templateUrl: "./post-context-menu-button.component.html",
  styleUrls: ["./post-context-menu-button.component.scss"],
})
export class PostContextMenuButtonComponent implements OnInit {
  @Input() post: Post;

  public isMine: boolean = false;

  constructor(private router: Router, private userService: UserService) {}

  ngOnInit(): void {
    this.userService.user$.subscribe((user) => {
      if (user && user.id == this.post.owner.id) {
        this.isMine = true;
      }
    });
  }

  handleEdit() {
    this.router.navigate(["/posts/edit/", this.post.uuid]);
  }
}
