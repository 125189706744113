export enum BillingProvider {
  Stripe = "stripe",
  Paypal = "paypal",
  Internal = "internal",
  Apple = "apple",
  Google = "google",
}

export interface Customer {
  stripe_id: string;
  default_payment_method?: any;
  address?: any;
  metadata?: any;
  is_pro: boolean;
}

export interface Product {
  id: string;
  metadata: any;
  name: string;
  prices: ProductPrice[];
}

export interface ProductPriceRecurring {
  interval: string;
}

export interface ProductPrice {
  id: string;
  product: string;
  unit_amount: number;
  currency: string;
  recurring: ProductPriceRecurring;
}

export interface Coupon {
  id: string;
  name: string;
  amount_off?: number;
  duration?: string;
  duration_in_months?: number;
  percent_off?: number;
  valid: boolean;
}

export interface GenericSubscription {
  id: number;
  uuid: String;
  identifier: String;
  is_active: boolean;
  platform: BillingProvider;
  product: String;
  resource: any;

  metadata: any;
  created_at: Date;
  updated_at: Date;
  starts_at: Date;
  expires_at: Date;
}

export interface Subscription {
  id: string;
  billing_cycle_anchor: number;
  cancel_at: number;
  cancel_at_period_end: boolean;
  collection_method: string;
  created: number;
  current_period_end: number;
  current_period_start: number;
  customer: string;
  days_until_due: number;
  default_payment_method: string;
  latest_invoice: string;
  metadata: any;
  plan: any;
  quantity: number;
  start_date: number;
  status: string;
  items: any;
}
