<div class="container text-light">
  <div class="row">
    <div class="col-12 col-md-5 offset-md-3">
      <h2>Forgot Password</h2>
      <app-forgot-password-form></app-forgot-password-form>
      <!-- 
      <hr />
      <p class="text-center">
        <small>Suddenly remembered?</small><br />
        <a routerLink="/auth/login">Sign in</a>
      </p> -->
    </div>
  </div>
</div>
