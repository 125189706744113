import { Component, Inject, OnInit } from "@angular/core";
import { Product, ProductPrice } from "src/app/billing/models/billing.models";
import { BillingService } from "src/app/billing/services/billing.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { ModalRef } from "../../modal/modal-ref";
import { MODAL_DATA } from "../../modal/modal.service";

@Component({
  selector: "app-subscribe-modal",
  templateUrl: "./subscribe-modal.component.html",
  styleUrls: ["./subscribe-modal.component.scss"],
})
export class SubscribeModalComponent implements OnInit {
  public products: Product[] = null;

  constructor(
    public modalRef: ModalRef<SubscribeModalComponent>,
    @Inject(MODAL_DATA) public data: any,
    private billingService: BillingService,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    this.products = await this.billingService.listProducts().toPromise();
  }

  async subscribe(price: ProductPrice) {
    try {
      const data = await this.billingService
        .createSubscription(price)
        .toPromise();
      this.toastService.success("Subscription added");
      this.modalRef.close(data);
    } catch (e) {
      console.log(e);
      this.toastService.error();
    }
  }

  close() {
    this.modalRef.close(false);
  }
}
