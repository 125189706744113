<ng-container>
  <div class="modal-backdrop">
    <div class="modal modal--small">
      <div class="modal__close" (click)="close()">
        <i class="fas fa-times"></i>
      </div>
      <div class="dialog">
        <h3>Subscribe</h3>

        <div *ngFor="let product of products">
          <h5 class="text-center">{{ product.name }}</h5>
          <div class="row">
            <div class="col" *ngFor="let price of product.prices">
              <div class="card p-3">
                <div class="price text-center">
                  <h5 *ngIf="price.recurring.interval == 'year'">Annual</h5>
                  <h5 *ngIf="price.recurring.interval == 'month'">Monthly</h5>
                  {{ price.unit_amount / 100 | currency }}
                  <ng-container *ngIf="price.recurring.interval == 'year'"
                    >/yr</ng-container
                  >
                  <ng-container *ngIf="price.recurring.interval == 'month'"
                    >/mo</ng-container
                  >
                </div>
                <div class="button-container text-center pt-2">
                  <button class="btn btn-primary" (click)="subscribe(price)">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
