<nav *ngIf="pages.length > 1">
  <ul class="pagination">
    <li class="page-item" *ngIf="showFirst()">
      <a class="page-link" (click)="selectPage(1)"> 1... </a>
    </li>

    <li class="page-item" [ngClass]="{ disabled: !hasPrevious() }">
      <a class="page-link" (click)="selectPage(page - 1)">
        <i class="fa fa-chevron-left"></i>
      </a>
    </li>
    <li
      *ngFor="let pageNumber of pages"
      class="page-item"
      [ngClass]="{ active: pageNumber === page }"
    >
      <a class="page-link" (click)="selectPage(pageNumber)">
        {{ pageNumber }}
      </a>
    </li>
    <li class="page-item" [ngClass]="{ disabled: !hasNext() }">
      <a class="page-link" (click)="selectPage(page + 1)">
        <i class="fa fa-chevron-right"></i>
      </a>
    </li>
    <li class="page-item" *ngIf="showLast()">
      <a class="pgae-link" (click)="selectPage(pageCount)">
        ...{{ pageCount }}
      </a>
    </li>
  </ul>
</nav>
