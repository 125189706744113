import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { routedComponents, AssetRoutingModule } from "./asset-routing.module";
import { AssetCreateFormComponent } from "./components/asset-create-form/asset-create-form.component";
import { SharedModule } from "src/app/shared/shared.module";
import { HdrPreviewComponent } from './components/hdr-preview/hdr-preview.component';
import { HdrToningPreviewComponent } from './components/hdr-toning-preview/hdr-toning-preview.component';
import { AssetPreviewScreenComponent } from './screens/asset-preview-screen/asset-preview-screen.component';

@NgModule({
  declarations: [...routedComponents, AssetCreateFormComponent, HdrPreviewComponent, HdrToningPreviewComponent, AssetPreviewScreenComponent],
  imports: [SharedModule, AssetRoutingModule],
})
export class AssetModule {}
