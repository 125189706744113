<div class="py-1">
  <small
    class="text-danger"
    *ngIf="
      textInput.touched && textInput.errors && textInput.hasError('required')
    "
    >{{ name }} is required.</small
  >
  <small
    class="text-danger"
    *ngIf="
      textInput.touched && textInput.errors && textInput.hasError('minlength')
    "
    >{{ name }} must be at least
    {{ textInput.errors.minlength.requiredLength }} characters.</small
  >
  <small
    class="text-danger"
    *ngIf="
      textInput.touched && textInput.errors && textInput.hasError('maxlength')
    "
    >{{ name }} can't be more than
    {{ textInput.errors.maxlength.requiredLength }} characters.</small
  >
  <small
    class="text-danger"
    *ngIf="textInput.touched && textInput.errors && textInput.hasError('email')"
    >{{ name }} must be a valid email address.</small
  >

  <small
    class="text-danger"
    *ngIf="
      textInput.touched && textInput.errors && textInput.hasError('unavailable')
    "
    >{{ name }} is not available.</small
  >

  <pre *ngIf="debug && textInput.errors">{{ textInput.errors | json }}</pre>
</div>
