<div class="row" [formGroup]="form" *ngIf="ready">
  <div class="col">
    <h4>Mode:</h4>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link active"
          [ngClass]="{ active: mode == modes.SingleImage }"
          href="#"
          (click)="mode = modes.SingleImage; $event.preventDefault()"
          >Single File</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          [ngClass]="{ active: mode == modes.Stitching }"
          href="#"
          (click)="mode = modes.Stitching; $event.preventDefault()"
          >Panoramic Stitch</a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          [ngClass]="{ active: mode == modes.StreetView }"
          href="#"
          (click)="mode = modes.StreetView; $event.preventDefault()"
          >StreetView</a
        >
      </li>
    </ul>

    <div class="form-group pt-2">
      <label>Title</label>
      <input type="text" class="form-control" formControlName="title" />
      <app-text-error-message
        [textInput]="form.get('title')"
        name="Title"
      ></app-text-error-message>
    </div>

    <ng-container *ngIf="mode == modes.SingleImage">
      <app-image-uploader
        (onImageChange)="handleImageChange($event)"
        [initialValue]="asset.output_image.url"
      ></app-image-uploader>
      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label>Adjust Gamma</label>
            <input class="form-control" type="number" formControlName="gamma" />
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label>Adjust Saturation</label>
            <input
              class="form-control"
              type="number"
              formControlName="saturation"
            />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label>Adjust Contrast</label>
            <input
              class="form-control"
              type="number"
              formControlName="contrast"
            />
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="mode == modes.Stitching">
      <app-image-uploader
        (onImagesChange)="handleImagesChange($event)"
        [multiple]="true"
      ></app-image-uploader>
    </ng-container>

    <ng-container *ngIf="mode == modes.StreetView">
      <div class="form-group">
        <app-places-autocomplete
          (setAddress)="handleAddressChange($event)"
        ></app-places-autocomplete>
      </div>

      <ng-container *ngIf="!processingStreetview">
        <img *ngIf="streetviewImage" [src]="streetviewImage" width="100%" />
      </ng-container>
      <ng-container *ngIf="processingStreetview">
        <i class="fa fa-spin fa-spinner"></i>
      </ng-container>
    </ng-container>

    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-danger" *ngIf="asset.id" (click)="deleteAsset()">
          Delete
        </button>
        <button
          class="btn btn-primary"
          (click)="save()"
          [disabled]="
            form.invalid ||
            (mode == modes.SingleImage && !form.value.input_image) ||
            (mode == modes.Stitching && !form.value.input_images.length) ||
            (mode == modes.StreetView && !streetviewImage)
          "
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
