import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Asset } from "../../models/asset.model";
import { ToningService } from "../../services/toning.service";

@Component({
  selector: "app-hdr-toning-preview",
  templateUrl: "./hdr-toning-preview.component.html",
  styleUrls: ["./hdr-toning-preview.component.scss"],
})
export class HdrToningPreviewComponent implements OnInit {
  @Input() asset: Asset;

  @ViewChild("container", { static: true })
  public container: ElementRef<HTMLElement>;

  @ViewChild("rendererCanvas", { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  constructor(private toningService: ToningService) {}

  ngOnInit(): void {
    const size = this.container.nativeElement.offsetWidth;

    this.toningService.createScene(this.rendererCanvas, this.asset, size, size);
    this.resize();
    this.toningService.animate();

    window.addEventListener("resize", () => {
      this.resize();
    });
  }

  resize() {
    const size = this.container.nativeElement.offsetWidth;
    this.toningService.setSize(size, size);
  }
}
