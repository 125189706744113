<div
  class="py-3"
  class="text-light"
  *ngIf="ready && !completed && form"
  [formGroup]="form"
>
  <div class="form-group text-light">
    <label>New Email Address</label>
    <input type="text" class="form-control" formControlName="email" />
    <app-text-error-message
      [textInput]="form.get('email')"
      name="Email"
    ></app-text-error-message>
  </div>

  <div class="text-right">
    <button class="btn btn-secondary" (click)="submit()">Submit</button>
  </div>
</div>

<div *ngIf="completed" class="text-light">
  <p>Check your email!</p>
</div>
