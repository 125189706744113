import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaginatedResponse } from "src/app/common/models/paginated-response.model";
import { ApiService } from "src/app/common/services/api.service";
import { Asset } from "../models/asset.model";

@Injectable({
  providedIn: "root",
})
export class AssetService {
  private baseUrl = "/content/asset";

  constructor(private api: ApiService) {}

  retrieve(uuid: String): Observable<Asset> {
    return this.api.get(this.api.url(`${this.baseUrl}/${uuid}`));
  }

  list(
    page: number = 1,
    limit: number = 10,
    params: any = {},
    url: string = `${this.baseUrl}`
  ): Observable<PaginatedResponse<Asset>> {
    const data = {
      page: page,
      limit: limit,
      ...params,
    };

    return this.api.get(this.api.url(url), { params: data });
  }

  create(data: any = {}): Observable<Asset> {
    return this.api.post(this.api.url(`${this.baseUrl}`), data);
  }
  stitch(uuid: string): Observable<Asset> {
    return this.api.post(this.api.url(`${this.baseUrl}/${uuid}/stitch/`));
  }

  patch(id: number, data: any = {}): Observable<Asset> {
    return this.api.patch(this.api.url(`${this.baseUrl}/${id}`), data);
  }

  put(id: number, data: any = {}): Observable<Asset> {
    return this.api.put(this.api.url(`${this.baseUrl}/${id}`), data);
  }

  delete(id: number): Observable<void> {
    return this.api.delete(this.api.url(`${this.baseUrl}/${id}`));
  }

  outputImageUrl(asset: Asset): string {
    return this.api.url(`${this.baseUrl}/${asset.uuid}/output`);
  }
}
