import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-post-list-screen",
  templateUrl: "./post-list-screen.component.html",
  styleUrls: ["./post-list-screen.component.scss"],
})
export class PostListScreenComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
