<nav
  class="navbar navbar-expand-md navbar-dark"
  *ngIf="!router.url.includes('preview')"
>
  <div>
    <a class="navbar-brand" routerLink="/"><h1>HDReye</h1></a>
  </div>
</nav>

<div>
  <router-outlet></router-outlet>
</div>

<div
  class="footer bg-dark text-light pt-3 pb-2"
  *ngIf="!router.url.includes('preview')"
>
  <div class="container d-flex">
    <a href="mailto:support@hdreye.app" class="text-light">Contact</a
    >&nbsp;&nbsp;&nbsp;&nbsp;
    <a routerLink="/terms" class="text-light">Terms</a>&nbsp;&nbsp;&nbsp;&nbsp;
    <a routerLink="/privacy" class="text-light">Privacy</a
    >&nbsp;&nbsp;&nbsp;&nbsp;
  </div>
</div>

<div class="toast-container p-3">
  <ngb-toast
    *ngFor="let toast of toastService.toasts"
    [header]="toast.header"
    [class]="toast.className"
    [autohide]="toast.autohide"
    [delay]="toast.delay || 3000"
    (hide)="toastService.remove(toast)"
    >{{ toast.body }}</ngb-toast
  >
</div>
