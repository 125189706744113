import { Component, Input, OnInit } from "@angular/core";
import { Post } from "src/app/content/post/models/post.model";

@Component({
  selector: "app-favorite-button",
  templateUrl: "./favorite-button.component.html",
  styleUrls: ["./favorite-button.component.scss"],
})
export class FavoriteButtonComponent implements OnInit {
  @Input() post: Post;

  public isActive: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  handleClick() {
    this.isActive = !this.isActive;
  }
}
