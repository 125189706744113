import { Component, Input, OnInit } from "@angular/core";
import { Post } from "src/app/content/post/models/post.model";

@Component({
  selector: "app-post-draft-indicator",
  templateUrl: "./post-draft-indicator.component.html",
  styleUrls: ["./post-draft-indicator.component.scss"],
})
export class PostDraftIndicatorComponent implements OnInit {
  @Input() post: Post;

  constructor() {}

  ngOnInit(): void {}
}
