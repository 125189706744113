import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AuthRoutingModule, routedComponents } from "./auth-routing.module";
import { SharedModule } from "../shared/shared.module";
import { RegisterFormComponent } from "./components/register-form/register-form.component";
import { LoginFormComponent } from "./components/login-form/login-form.component";
import { ForgotPasswordScreenComponent } from "./screens/forgot-password-screen/forgot-password-screen.component";
import { ForgotPasswordFormComponent } from "./components/forgot-password-form/forgot-password-form.component";
import { EmailChangeScreenComponent } from "./screens/email-change-screen/email-change-screen.component";
import { EmailChangeFormComponent } from "./components/email-change-form/email-change-form.component";
import { SubscribeScreenComponent } from "./screens/subscribe-screen/subscribe-screen.component";
import { AuthModalComponent } from "./components/modals/auth-modal/auth-modal.component";
import { EmailConfirmScreenComponent } from "./screens/email-confirm-screen/email-confirm-screen.component";

@NgModule({
  declarations: [
    ...routedComponents,
    RegisterFormComponent,
    LoginFormComponent,
    ForgotPasswordFormComponent,
    EmailChangeFormComponent,
    AuthModalComponent,
    EmailConfirmScreenComponent,
  ],
  imports: [SharedModule, AuthRoutingModule],
})
export class AuthModule {}
