import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalService } from "src/app/shared/components/modal";
import { ConfirmModalComponent } from "src/app/shared/components/modals/confirm-modal/confirm-modal.component";
import { ToastService } from "src/app/shared/services/toast.service";
import { AssetFormService } from "../../services/asset-form.service";
import { CaptureMode } from "../../models/asset.model";
import { MediaService } from "src/app/media/services/media.service";
import { FileAsset } from "src/app/media/models/asset.model";

declare const google: any;
declare const PANOMNOM: any;

@Component({
  selector: "app-asset-create-form",
  templateUrl: "./asset-create-form.component.html",
  styleUrls: ["./asset-create-form.component.scss"],
})
export class AssetCreateFormComponent implements OnInit {
  @Input() uuid: String;
  public ready: boolean;
  public modes = CaptureMode;
  public mode: CaptureMode = CaptureMode.SingleImage;

  public processingStreetview: boolean = false;
  public streetviewImage: string = null;

  constructor(
    private assetFormService: AssetFormService,
    private router: Router,
    private toastService: ToastService,
    private modalService: ModalService,
    private ref: ChangeDetectorRef,
    private mediaService: MediaService
  ) {}

  public get asset() {
    return this.assetFormService.asset;
  }

  public get form() {
    return this.assetFormService.form;
  }

  async ngOnInit() {
    if (this.uuid) {
      try {
        await this.assetFormService.retrieveAsset(this.uuid);
      } catch (e) {
        this.toastService.error("This asset is not editable");
        this.router.navigate(["/"]);
        return;
      }
    } else {
      this.assetFormService.clearForm();
    }

    this.ready = true;
  }

  handleImageChange(url: string) {
    this.assetFormService.updateImage(url);
  }

  handleImagesChange(assets: FileAsset[]) {
    const ids = assets.map((a) => a.id);
    this.assetFormService.updateImages(ids);
  }

  handleAddressChange(address: any) {
    if (!address?.geometry?.location?.lat) {
      return;
    }
    this.processingStreetview = true;

    const loader = new PANOMNOM.GoogleStreetViewLoader();

    let _this = this;

    loader.addEventListener("load", async (event) => {
      const { canvas } = event.target;

      var img = canvas.toDataURL("image/jpeg");

      const asset = await this.mediaService.uploadBase64(img).toPromise();

      this.assetFormService.updateImage(asset.url);

      this.streetviewImage = asset.url;
      this.processingStreetview = false;

      setTimeout(() => {
        this.processingStreetview = true;
      }, 0);

      setTimeout(() => {
        this.processingStreetview = false;
      }, 0);
    });

    const latLng = new google.maps.LatLng(
      address.geometry.location.lat(),
      address.geometry.location.lng()
    );

    var streetviewService = new google.maps.StreetViewService();

    streetviewService.getPanoramaByLocation(
      latLng,
      50,
      (result: any, status) => {
        const panoId = result?.links?.length ? result.links[0].pano : null;
        loader.load(panoId, 3);
      }
    );
  }

  async save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    const success = await this.assetFormService.save(this.mode);
    if (success) {
      await this.assetFormService.stitch();
      this.toastService.success("Asset sent for stitching.");

      this.router.navigate(["/assets/", this.asset.uuid]);
    } else {
      this.toastService.error();
    }
  }

  async deleteAsset() {
    this.modalService
      .create(ConfirmModalComponent, {
        data: {},
      })
      .onClosed()
      .subscribe(async (confirmed) => {
        if (confirmed) {
          const success = await this.assetFormService.deleteAsset();
          if (success) {
            this.toastService.success("Asset deleted.");

            this.router.navigate(["/"]);
          } else {
            this.toastService.error();
          }
        }
      });
  }
}
