import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-forgot-password-screen",
  templateUrl: "./forgot-password-screen.component.html",
  styleUrls: ["./forgot-password-screen.component.scss"],
})
export class ForgotPasswordScreenComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
