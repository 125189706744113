import { Component, Input, OnInit } from "@angular/core";
import { User } from "../../../user/models/user.model";

@Component({
  selector: "app-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements OnInit {
  @Input() user: User;
  @Input() size: number = 42;
  @Input() interactive: boolean = true;

  constructor() {}

  ngOnInit(): void {}

  get imageUrl(): string {
    if (this.user.image != null) {
      return this.user.image;
    }
    return "/assets/placeholders/avatar_placeholder.gif";
  }

  get link(): string {
    return `/profile/${this.user.uuid}`;
  }
}
