import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BillingScreenComponent } from "./screens/billing-screen/billing-screen.component";
import { ProfileScreenComponent } from "./screens/profile-screen/profile-screen.component";
import { SettingsScreenComponent } from "./screens/settings-screen/settings-screen.component";

const userRoutes: Routes = [
  {
    path: "",
    component: ProfileScreenComponent,
  },
  {
    path: "settings",
    component: SettingsScreenComponent,
  },
  {
    path: "settings/billing",
    component: BillingScreenComponent,
  },
  {
    path: ":uuid",
    component: ProfileScreenComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(userRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class UserRoutingModule {}

export const routedComponents = [
  ProfileScreenComponent,
  SettingsScreenComponent,
];
