<div class="engine-wrapper" #container>
  <canvas #rendererCanvas id="renderCanvas"></canvas>
</div>

<!-- <div class="loader" *ngIf="loading">
  <div class="text-center text-light">
    <div class="mt-5 pt-5">
      <i class="fa fa-spin fa-spinner mt-4"></i>
      <div>
        <small>This will take some time.</small>
      </div>
    </div>
  </div>
</div> -->
