<div class="form-group">
  <label>{{ label }}</label>
  <div class="d-flex align-items-center">
    <div class="pr-2">
      <ng-container *ngIf="!multiple">
        <img
          *ngIf="!processing && imagePreview"
          class="image-preview"
          [src]="imagePreview.url"
          width="200"
        />
        <div
          class="d-flex justify-content-center align-items-center"
          *ngIf="processing"
          [style.width.px]="200"
          [style.height.px]="200"
        >
          <i class="fa fa-spin fa-spinner"></i>
        </div>
      </ng-container>
    </div>
    <div>
      <input
        #imageInput
        type="file"
        class="form-control-file"
        accept="image/*"
        [multiple]="multiple"
        (change)="handleImageChange()"
      />
      <div class="py-2">
        <i class="fa fa-spin fa-spinner" *ngIf="multiple && processing"></i>
      </div>
    </div>
  </div>

  <ng-container *ngIf="multiple">
    <div class="row">
      <div
        class="col-12 col-md-3"
        *ngFor="let image of imagePreviews; let i = index"
      >
        <div class="card p-2">
          <img [src]="image.url" width="100%" />
          <div class="text-right pt-1">
            <div class="btn btn-sm btn-danger" (click)="removeImage(i)">
              <i class="fa fa-trash"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
