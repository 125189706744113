import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { PaymentMethod } from "@stripe/stripe-js";
import {
  Customer,
  Subscription,
  Product,
  GenericSubscription,
} from "src/app/billing/models/billing.models";
import { BillingService } from "src/app/billing/services/billing.service";
import { ModalService } from "src/app/shared/components/modal";
import { ConfirmModalComponent } from "src/app/shared/components/modals/confirm-modal/confirm-modal.component";
import { CreditCardModalComponent } from "src/app/shared/components/modals/credit-card-modal/credit-card-modal.component";
import { SubscribeModalComponent } from "src/app/shared/components/modals/subscribe-modal/subscribe-modal.component";
import { ToastService } from "src/app/shared/services/toast.service";

@Component({
  selector: "app-billing-screen",
  templateUrl: "./billing-screen.component.html",
  styleUrls: ["./billing-screen.component.scss"],
})
export class BillingScreenComponent implements OnInit {
  public ready: boolean = false;
  public customer: Customer;
  public paymentMethods: PaymentMethod[] = null;
  public subscription: Subscription = null;
  public subscriptions: GenericSubscription[] = null;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private toastService: ToastService,
    private billingService: BillingService
  ) {}

  public get defaultPaymentMethodId() {
    return this.customer.metadata?.stripe?.default_payment_method;
  }

  public setDefaultPaymentMethodId(id: String) {
    if (!this.customer.metadata.stripe) {
      this.customer.metadata.stripe = {
        default_payment_method: id,
      };
    } else {
      this.customer.metadata.stripe.default_payment_method = id;
    }
  }

  ngOnInit() {
    this.load();
  }

  async load() {
    this.customer = await this.billingService.me().toPromise();

    this.paymentMethods = await this.billingService
      .listPaymentMethods()
      .toPromise();

    this.subscriptions = await this.billingService
      .listAllSubscriptions()
      .toPromise();

    const test = await this.billingService.listSubscriptions().toPromise();
    console.log(test);

    this.ready = true;
  }

  async setDefaultPaymentMethod(paymentMethod: PaymentMethod) {
    try {
      await this.billingService
        .updatePaymentMethod(paymentMethod, true)
        .toPromise();
      this.toastService.success("Default payment method updated");

      this.setDefaultPaymentMethodId(paymentMethod.id);
    } catch (e) {
      console.log(e);
      this.toastService.error();
    }
  }

  async removePaymentMethod(paymentMethod: PaymentMethod, index: number) {
    try {
      await this.billingService.deletePaymentMethod(paymentMethod).toPromise();
      this.paymentMethods.splice(index, 1);
      this.toastService.success("Payment method deleted");
    } catch (e) {
      console.log(e);
      this.toastService.error();
    }
  }

  showPaymentMethodModal() {
    const modalRef = this.modalService.create(CreditCardModalComponent, {
      data: {},
    });

    modalRef.onClosed().subscribe((paymentMethod) => {
      if (paymentMethod) {
        this.paymentMethods.push(paymentMethod);
        this.setDefaultPaymentMethodId(paymentMethod.id);
      }
    });
  }

  showSubscriptionModal() {
    if (!this.paymentMethods.length) {
      this.modalService
        .create(CreditCardModalComponent, { data: {} })
        .onClosed()
        .subscribe(async (paymentMethod: PaymentMethod) => {
          this.paymentMethods.push(paymentMethod);
          this.modalService
            .create(SubscribeModalComponent, {
              data: {},
            })
            .onClosed()
            .subscribe((subscription: Subscription) => {
              // this.subscription = subscription;
              this.load();
            });
        });
      return;
    }

    this.modalService
      .create(SubscribeModalComponent, {
        data: {},
      })
      .onClosed()
      .subscribe((subscription: Subscription) => {
        // this.subscription = subscription;
        this.load();
      });
  }

  cancelSubscription(subscription: Subscription) {
    this.modalService
      .create(ConfirmModalComponent, {
        data: {
          title: "Are you sure?",
          message: "Are you sure you want to cancel your subscription?",
          danger: true,
          confirmText: "Cancel Subscription",
          cancelText: "Nevermind",
        },
      })
      .onClosed()
      .subscribe(async (confirmed) => {
        if (!confirmed) {
          return;
        }
        try {
          await this.billingService
            .cancelSubscription(subscription)
            .toPromise();

          this.toastService.success("Subscription has been cancelled.");
          await this.load();
        } catch (e) {
          this.toastService.error();
        }
      });
  }
  async renewSubscription(subscription: Subscription) {
    if (!this.paymentMethods.length) {
      this.modalService
        .create(CreditCardModalComponent, { data: {} })
        .onClosed()
        .subscribe(async (paymentMethod: PaymentMethod) => {
          if (paymentMethod) {
            this.paymentMethods.push(paymentMethod);
            this.setDefaultPaymentMethodId(paymentMethod.id);

            try {
              await this.billingService
                .renewSubscription(subscription)
                .toPromise();

              await this.load();
              this.toastService.success("Subscription has been renewed");
            } catch (e) {
              this.toastService.error();
            }
          }
        });

      return;
    }

    try {
      await this.billingService.renewSubscription(subscription).toPromise();
      await this.load();
      this.toastService.success("Subscription has been renewed");
    } catch (e) {
      this.toastService.success("A problem occurred.");
    }
  }

  handleSuccess() {
    this.router.navigate(["/profile/settings"]);
  }

  handleError() {
    this.router.navigate(["/profile/settings"]); // temp
  }
}
