<div class="container">
  <div class="col-12 col-md-5 offset-md-3">
    <h2>Sign in</h2>

    <app-login-form></app-login-form>

    <hr />
    <p class="text-center">
      <small>No account?</small><br />
      <a routerLink="/auth/register" class="text-primary">Sign up</a>
    </p>
  </div>
</div>
