import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PaginatedResponse } from "src/app/common/models/paginated-response.model";
import { ApiService } from "src/app/common/services/api.service";
import { User } from "src/app/user/models/user.model";
import { Post } from "../models/post.model";

@Injectable({
  providedIn: "root",
})
export class PostService {
  private baseUrl = "/content/post";

  constructor(private api: ApiService) {}

  retrieve(uuid: String): Observable<Post> {
    return this.api.get(this.api.url(`${this.baseUrl}/${uuid}`));
  }

  retrieveMine(uuid: String): Observable<Post> {
    return this.api.get(this.api.url(`${this.baseUrl}/me/${uuid}`));
  }

  private _list(
    page: number = 1,
    limit: number = 10,
    params: any = {},
    url: string = `${this.baseUrl}`
  ): Observable<PaginatedResponse<Post>> {
    const data = {
      page: page,
      limit: limit,
      ...params,
    };

    return this.api.get(this.api.url(url), { params: data });
  }

  list(
    page: number = 1,
    limit: number = 10
  ): Observable<PaginatedResponse<Post>> {
    return this._list(page, limit);
  }

  user(
    user: User,
    page: number = 1,
    limit: number = 10
  ): Observable<PaginatedResponse<Post>> {
    const data = {
      owner: user.id,
    };
    return this._list(page, limit, data);
  }

  me(
    page: number = 1,
    limit: number = 10
  ): Observable<PaginatedResponse<Post>> {
    return this._list(page, limit, {}, `${this.baseUrl}/me`);
  }

  create(data: any = {}): Observable<Post> {
    return this.api.post(this.api.url(`${this.baseUrl}`), data);
  }

  patch(id: number, data: any = {}): Observable<Post> {
    return this.api.patch(this.api.url(`${this.baseUrl}/${id}`), data);
  }

  put(id: number, data: any = {}): Observable<Post> {
    return this.api.put(this.api.url(`${this.baseUrl}/${id}`), data);
  }

  delete(id: number): Observable<void> {
    return this.api.delete(this.api.url(`${this.baseUrl}/${id}`));
  }
}
