<div class="container">
  <div class="col-12 col-md-5 offset-md-3">
    <h2>Sign up</h2>
    <app-register-form></app-register-form>
    <hr />
    <p class="text-center">
      <small>Already have an account?</small><br />
      <a routerLink="/auth/login" class="text-primary">Sign in</a>
    </p>
  </div>
</div>
