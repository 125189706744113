import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/auth/services/auth.service";
import { User } from "../models/user.model";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class SettingsFormService {
  public user: User;
  public form: FormGroup;

  private currentUsername: String;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService
  ) {}

  async buildForm() {
    this.user = await this.userService.me().toPromise();

    this.currentUsername = this.user.username;

    this.form = this.fb.group({
      email: [this.user.email, [Validators.required, Validators.email]],
      username: [
        this.user.username,
        [Validators.required, Validators.minLength(3)],
      ],
      first_name: [this.user.first_name, []],
      last_name: [this.user.last_name, []],
      image: [this.user.image],
    });
  }

  updateImage(url: String) {
    this.form.patchValue({ image: url });
  }

  async save() {
    const { username } = this.form.value;

    if (username != this.currentUsername) {
      const usernameAvailable = await this.authService
        .usernameAvailable(username)
        .toPromise();

      if (!usernameAvailable) {
        this.form.controls["username"].setErrors({ unavailable: true });
        return false;
      }
    }

    const formData = {
      ...this.user,
      ...this.form.value,
    };

    try {
      await this.userService.updateMe(formData).toPromise();

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
