import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class ForgotPasswordFormService {
  public form: FormGroup;
  public confirmationForm: FormGroup;

  constructor(private fb: FormBuilder, private authService: AuthService) {}

  buildForm() {
    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

  buildConfirmationForm() {
    this.confirmationForm = this.fb.group({
      password: ["", [Validators.required, Validators.minLength(8)]],
    });
  }

  async requestPasswordReset() {
    const { email } = this.form.value;

    try {
      await this.authService.requestPasswordReset(email).toPromise();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async validatePasswordReset(uuid: string, token: string) {
    try {
      await this.authService.validatePasswordReset(uuid, token).toPromise();
      return true;
    } catch (e) {
      return false;
    }
  }

  async confirmPasswordReset(uuid: string, token: string) {
    if (this.confirmationForm.invalid) {
      return;
    }

    const { password } = this.confirmationForm.value;

    try {
      await this.authService
        .confirmPasswordReset(uuid, token, password)
        .toPromise();
      return true;
    } catch (e) {
      return false;
    }
  }
}
