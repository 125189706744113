import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Asset, AssetStatus, CaptureMode } from "../models/asset.model";
import { AssetService } from "./asset.service";

@Injectable({
  providedIn: "root",
})
export class AssetFormService {
  public asset: Asset;
  public form: FormGroup;

  constructor(private fb: FormBuilder, private assetService: AssetService) {}

  buildForm(asset: Asset) {
    this.asset = asset;
    this.form = this.fb.group({
      title: [asset.title, [Validators.required]],

      input_image: [],
      input_images: [[]],
      saturation: [1.0],
      gamma: [1.0],
      contrast: [1.0],
    });
  }

  async retrieveAsset(uuid: String) {
    const asset = await this.assetService.retrieve(uuid).toPromise();
    this.buildForm(asset);
  }

  clearForm() {
    this.buildForm({ status: AssetStatus.DRAFT });
  }

  updateImage(url: string) {
    this.form.patchValue({ input_image: url });
  }

  updateImages(ids: number[]) {
    this.form.patchValue({ input_images: ids });
  }

  async save(stitchingMode: CaptureMode) {
    let asset = this.asset;

    const formData: any = {
      // ...this.asset,
      title: this.form.value.title,
      // gamma: this.form.value.gamma,
      // saturation: this.form.value.saturation,
      // contrast: this.form.value.contrast,
      source_images: this.form.value.input_images,
    };

    if (
      stitchingMode == CaptureMode.SingleImage ||
      stitchingMode == CaptureMode.StreetView
    ) {
      formData.input_image = this.form.value.input_image;
    } else if (stitchingMode == CaptureMode.Stitching) {
      formData.input_images = this.form.value.input_images.join(",");
    } else {
      alert("Unhandled Mode...");
    }

    try {
      let updatedPost: Asset;

      if (asset.id) {
        updatedPost = await this.assetService
          .put(asset.id, formData)
          .toPromise();
      } else {
        updatedPost = await this.assetService.create(formData).toPromise();
      }

      if (updatedPost) {
        this.asset = updatedPost;
        return true;
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async stitch() {
    try {
      await this.assetService.stitch(this.asset.uuid).toPromise();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async deleteAsset() {
    try {
      await this.assetService.delete(this.asset.id).toPromise();
      this.clearForm();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
