import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class EmailChangeFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder, private authService: AuthService) {}

  buildForm() {
    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

  async requestEmailChange() {
    const { email } = this.form.value;

    const emailAvailable = await this.authService
      .emailAvailable(email)
      .toPromise();

    if (!emailAvailable) {
      this.form.controls.email.setErrors({ unavailable: true });
      return;
    }
    try {
      await this.authService.requestEmailChange(email).toPromise();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async validateEmailChange(uuid: string, token: string, payload: string) {
    try {
      await this.authService
        .validateEmailChange(uuid, token, payload)
        .toPromise();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async confirmEmailChange(uuid: string, token: string, payload: string) {
    try {
      await this.authService
        .confirmEmailChange(uuid, token, payload)
        .toPromise();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
