import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { title } from "process";
import { ModalService } from "src/app/shared/components/modal";
import { ConfirmModalComponent } from "src/app/shared/components/modals/confirm-modal/confirm-modal.component";
import { ToastService } from "src/app/shared/services/toast.service";
import { PostStatus } from "../../models/post.model";
import { PostFormService } from "../../services/post-form.service";
import { PostService } from "../../services/post.service";

@Component({
  selector: "app-post-form",
  templateUrl: "./post-form.component.html",
  styleUrls: ["./post-form.component.scss"],
})
export class PostFormComponent implements OnInit {
  @Input() uuid: String;
  public ready: boolean;

  constructor(
    public postFormService: PostFormService,
    private router: Router,
    private toastService: ToastService,
    private modalService: ModalService
  ) {}

  public get post() {
    return this.postFormService.post;
  }

  public get form() {
    return this.postFormService.form;
  }

  async ngOnInit() {
    if (this.uuid) {
      try {
        await this.postFormService.retrievePost(this.uuid);
      } catch (e) {
        this.toastService.error("This post is not editable");
        this.router.navigate(["/"]);
        return;
      }
    } else {
      this.postFormService.clearForm();
    }
    this.ready = true;
  }

  handleImageChange(url: String) {
    this.postFormService.updateImage(url);
  }

  handleTagsChange(tags: String[]) {
    this.postFormService.updateTags(tags);
  }

  async save(publish: boolean = false) {
    if (publish) {
      this.form.markAllAsTouched();

      if (this.form.invalid) {
        return;
      }
    }

    const success = await this.postFormService.save(publish);
    if (success) {
      if (publish) {
        this.toastService.success("Post published successfully.");
        this.router.navigate(["/posts/", this.post.uuid]);
      } else {
        this.toastService.success("Draft saved successfully.");
      }
    } else {
      this.toastService.error();
    }
  }

  async unpublish() {
    const success = await this.postFormService.unpublish();

    if (success) {
      this.toastService.success("Post unpublished.");
    } else {
      this.toastService.error();
    }
  }

  async deletePost() {
    this.modalService
      .create(ConfirmModalComponent, {
        data: {},
      })
      .onClosed()
      .subscribe(async (confirmed) => {
        if (confirmed) {
          const success = await this.postFormService.deletePost();
          if (success) {
            this.toastService.success("Post deleted.");

            this.router.navigate(["/"]);
          } else {
            this.toastService.error();
          }
        }
      });
  }
}
