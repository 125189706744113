import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AuthService } from "./auth/services/auth.service";
import { ToastService } from "./shared/services/toast.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public isNavbarCollapsed = true;

  constructor(
    private authService: AuthService,
    public toastService: ToastService,
    public router: Router
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isNavbarCollapsed = true;
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  preventDefault(event) {
    event.preventDefault();
  }
}
