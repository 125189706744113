import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import * as filestack from "filestack-js";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FileAsset } from "../models/asset.model";

@Injectable({
  providedIn: "root",
})
export class MediaService {
  constructor(private http: HttpClient) {}

  upload(file): Observable<FileAsset> {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    return this.http
      .post(`${environment.apiDomain}/media/file/`, formData)
      .pipe(map((file: FileAsset) => file));
  }

  uploadBase64(string): Observable<FileAsset> {
    const file = this.DataURIToBlob(string);
    return this.upload(file);
  }

  private DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
}
