import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-credit-card-icon",
  templateUrl: "./credit-card-icon.component.html",
  styleUrls: ["./credit-card-icon.component.scss"],
})
export class CreditCardIconComponent implements OnInit {
  @Input() brand: string;

  constructor() {}

  public brands = ["visa", "mastercard", "amex", "discover"];

  ngOnInit(): void {}
}
