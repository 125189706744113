import { Injectable } from "@angular/core";
import { PaymentMethod } from "@stripe/stripe-js";
import { StripeService } from "ngx-stripe";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { PaginatedResponse } from "src/app/common/models/paginated-response.model";
import { ApiService } from "src/app/common/services/api.service";
import { environment } from "src/environments/environment";
import {
  ProductPrice,
  Coupon,
  Subscription,
  Customer,
  GenericSubscription,
  BillingProvider,
} from "../models/billing.models";

@Injectable({
  providedIn: "root",
})
export class BillingService {
  constructor(private api: ApiService, private stripeService: StripeService) {}

  // Customer
  me(): Observable<Customer> {
    return this.api.get(this.api.url("/billing/customer/me/"));
  }

  // Payment Methods

  createPaymentMethod(
    token: String,
    setAsDefault: boolean = true,
    provider: BillingProvider = BillingProvider.Stripe
  ) {
    return this.api.post(this.api.url(`/billing/${provider}/payment-method`), {
      token,
      set_default: setAsDefault,
    });
  }

  listPaymentMethods(
    provider: BillingProvider = BillingProvider.Stripe
  ): Observable<PaymentMethod[]> {
    return this.api.get(this.api.url(`/billing/${provider}/payment-method/`));
  }

  getPaymentMethod(
    id,
    provider: BillingProvider = BillingProvider.Stripe
  ): Observable<PaymentMethod> {
    return this.api.get(
      this.api.url(`/billing/${provider}/payment-method/${id}/`)
    );
  }

  updatePaymentMethod(
    paymentMethod: PaymentMethod,
    setDefault: boolean = true,
    provider: BillingProvider = BillingProvider.Stripe
  ): Observable<PaymentMethod> {
    const data = {
      set_default: setDefault,
    };
    return this.api.put(
      this.api.url(`/billing/${provider}/payment-method/${paymentMethod.id}`),
      data
    );
  }

  deletePaymentMethod(
    paymentMethod: PaymentMethod,
    provider: BillingProvider = BillingProvider.Stripe
  ) {
    return this.api.delete(
      this.api.url(`/billing/${provider}/payment-method/${paymentMethod.id}`)
    );
  }

  // Subscriptions

  createSubscription(
    price: ProductPrice,
    paymentMethod: PaymentMethod = null,
    coupon: Coupon = null,
    provider: BillingProvider = BillingProvider.Stripe
  ) {
    let data: any = {
      price: price.id,
    };

    if (paymentMethod) {
      data.default_payment_method = paymentMethod.id;
    }

    if (coupon) {
      data.coupon = coupon.id;
    }

    return this.api.post(
      this.api.url(`/billing/${provider}/subscription/`),
      data
    );
  }

  listAllSubscriptions(): Observable<GenericSubscription[]> {
    return this.api
      .get(this.api.url(`/billing/subscription/`), { params: { limit: 10 } })
      .pipe(
        map((data) => {
          return data.results;
        })
      );
  }

  listSubscriptions(
    provider: BillingProvider = BillingProvider.Stripe
  ): Observable<Subscription[]> {
    return this.api.get(this.api.url(`/billing/${provider}/subscription/`));
  }

  getSubscription(
    id,
    provider: BillingProvider = BillingProvider.Stripe
  ): Observable<Subscription> {
    return this.api.get(
      this.api.url(`/billing/${provider}/subscription/${id}/`)
    );
  }

  cancelSubscription(
    subscription: Subscription,
    provider: BillingProvider = BillingProvider.Stripe
  ) {
    const data = {
      cancel_at_period_end: true,
    };

    return this.api.post(
      this.api.url(
        `/billing/${provider}/subscription/${subscription.id}/cancel/`
      ),
      data
    );
  }

  renewSubscription(
    subscription: Subscription,
    provider: BillingProvider = BillingProvider.Stripe
  ) {
    const data = {
      cancel_at_period_end: false,
    };

    return this.api.post(
      this.api.url(
        `/billing/${provider}/subscription/${subscription.id}/renew/`
      ),
      data
    );
  }

  updateSubscriptionPaymentMethod(
    id,
    paymentMethod: PaymentMethod,
    provider: BillingProvider = BillingProvider.Stripe
  ) {
    const data = {
      default_payment_method: paymentMethod.id,
    };
    return this.api.put(
      this.api.url(`/billing/${provider}/subscription/${id}/`),
      data
    );
  }

  // Products

  listProducts(provider: BillingProvider = BillingProvider.Stripe) {
    return this.api.get(this.api.url(`/billing/${provider}/product/`));
  }

  getProduct(id, provider: BillingProvider = BillingProvider.Stripe) {
    return this.api.get(this.api.url(`/billing/${provider}/product/${id}/`));
  }

  // Invoices

  listInvoices(provider: BillingProvider = BillingProvider.Stripe) {
    return this.api.get(this.api.url(`/billing/${provider}/invoice/`));
  }

  getInvoices(id, provider: BillingProvider = BillingProvider.Stripe) {
    return this.api.get(this.api.url(`/billing/${provider}/invoice/${id}/`));
  }

  getCoupon(
    id,
    provider: BillingProvider = BillingProvider.Stripe
  ): Observable<Coupon> {
    return this.api.get(this.api.url(`/billing/${provider}/coupon/${id}/`));
  }
}
