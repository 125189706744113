import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "src/app/common/services/api.service";
import { User } from "../models/user.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private baseUrl = "/user";

  public userSource = new BehaviorSubject<User>(null);
  public user$ = this.userSource.asObservable();

  constructor(private api: ApiService) {}

  public retrieve(uuid: String): Observable<User> {
    return this.api.get(this.api.url(`${this.baseUrl}/${uuid}`));
  }

  public me(): Observable<User> {
    return this.api.get(this.api.url(`${this.baseUrl}`), {}).pipe(
      map((user: User) => {
        this.userSource.next(user);
        return user;
      })
    );
  }

  public updateMe(data: any): Observable<User> {
    return this.api.put(this.api.url(`${this.baseUrl}`), data);
  }
}
