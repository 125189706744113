import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-asset-create-screen",
  templateUrl: "./asset-create-screen.component.html",
  styleUrls: ["./asset-create-screen.component.scss"],
})
export class AssetCreateScreenComponent implements OnInit {
  public uuid: String;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const { uuid } = this.route.snapshot.params;
    this.uuid = uuid;
  }
}
