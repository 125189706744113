<form class="py-3" *ngIf="ready" [formGroup]="form" (submit)="handleLogin()">
  <div class="form-group">
    <label>Email</label>
    <input
      type="text"
      class="form-control"
      formControlName="email"
      [disabled]="processing"
    />
  </div>
  <div class="form-group">
    <label>Password</label>
    <input
      type="password"
      class="form-control"
      formControlName="password"
      [disabled]="processing"
    />
  </div>
  <div class="d-flex justify-content-between">
    <a
      routerLink="/auth/password-reset"
      class="text-primary"
      [target]="forgotPasswordNewTab ? '_blank' : '_self'"
      ><small>Forgot Password?</small></a
    >
    <button type="submit" class="btn btn-primary">
      <i class="fa fa-spin fa-spinner mr-1" *ngIf="processing"></i> Login
    </button>
  </div>
</form>
