import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { StripeCardComponent, StripeService } from "ngx-stripe";
import { BillingProvider } from "src/app/billing/models/billing.models";
import { BillingService } from "src/app/billing/services/billing.service";
import { ToastService } from "../../services/toast.service";

@Component({
  selector: "app-billing-form",
  templateUrl: "./billing-form.component.html",
  styleUrls: ["./billing-form.component.scss"],
})
export class BillingFormComponent implements OnInit {
  public processing: boolean = false;
  public provider: BillingProvider = BillingProvider.Stripe;

  public providers = BillingProvider;

  @Input() showCancel: boolean = false;
  @Output() onSuccess = new EventEmitter<any>();
  @Output() onError = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<any>();
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {};

  elementsOptions: StripeElementsOptions = {
    locale: "en",
  };

  stripeTest: FormGroup;

  constructor(
    private fb: FormBuilder,
    private billingService: BillingService,
    private stripeService: StripeService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ["", [Validators.required]],
    });
  }

  handleCancel() {
    this.onCancel.emit();
  }

  async addCard() {
    if (this.processing) {
      return;
    }
    this.processing = true;
    this.stripeService
      .createToken(this.card.element)
      .subscribe(async (result) => {
        if (result.token) {
          try {
            const data = await this.billingService
              .createPaymentMethod(result.token.id)
              .toPromise();
            this.toastService.success("Card added.");
            this.onSuccess.emit(data);
            this.processing = false;
          } catch (e) {
            this.toastService.error();
            this.onError.emit();
            this.processing = false;
          }
        } else if (result.error) {
          console.log(result.error.message);
          this.toastService.error();
          this.onError.emit();
          this.processing = false;
        }
      });
  }
}
