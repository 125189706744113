<ng-container *ngIf="ready">
  <h3>Billing</h3>

  <hr />
  <h4>Payment Methods</h4>

  <table class="table table-striped">
    <tbody>
      <tr *ngFor="let paymentMethod of paymentMethods; let i = index">
        <td>
          <app-credit-card-icon
            [brand]="paymentMethod.card.brand"
          ></app-credit-card-icon>
        </td>
        <td>
          <small
            [style.fontWeight]="
              customer.metadata?.stripe?.default_payment_method ==
              paymentMethod.id
                ? 'bold'
                : 'normal'
            "
          >
            **** **** **** {{ paymentMethod.card.last4 }}
            {{ paymentMethod.card.exp_month }}/{{ paymentMethod.card.exp_year }}
          </small>
          <small
            *ngIf="
              customer.metadata?.stripe?.default_payment_method ==
              paymentMethod.id
            "
            >[default]</small
          >
        </td>
        <td class="text-right">
          <div class="btn-group">
            <button
              class="btn btn-secondary btn-sm"
              *ngIf="
                customer.metadata?.stripe?.default_payment_method !=
                paymentMethod.id
              "
              (click)="setDefaultPaymentMethod(paymentMethod)"
            >
              Make Default
            </button>
            <button
              class="btn btn-danger btn-sm"
              *ngIf="
                customer.metadata?.stripe?.default_payment_method !=
                  paymentMethod.id ||
                (paymentMethods.length == 1 &&
                  subscriptions[0].resource.cancel_at_period_end)
              "
              (click)="removePaymentMethod(paymentMethod, i)"
            >
              Remove
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <button class="btn btn-primary" (click)="showPaymentMethodModal()">
    <i class="fa fa-plus mr-1"></i>
    Add payment method
  </button>

  <hr />

  <h4>Your Subscription(s)</h4>
  <ng-container *ngIf="subscriptions.length > 0; else noSubscription">
    <div *ngFor="let s of subscriptions">
      <ng-container *ngIf="s.platform == 'internal' && s.is_active">
        <h6>Custom</h6>
        <p>
          <small
            >You have an active subscription created by an admin on
            {{ s.created_at | date }}. To manage, please contact support.</small
          >
        </p>
      </ng-container>
      <ng-container *ngIf="s.platform == 'stripe' && s.resource as sub">
        <h6>Stripe</h6>
        <table class="table table-striped">
          <tbody>
            <tr>
              <td>Name</td>
              <td class="text-right">Pro Subscription</td>
            </tr>
            <tr>
              <td>Status</td>
              <td class="text-right" *ngIf="!sub.cancel_at">
                {{ sub.status | uppercase }}
              </td>
              <td class="text-right" *ngIf="sub.cancel_at">
                {{ sub.status | uppercase }} (Ends
                {{ sub.cancel_at * 1000 | date }})
              </td>
            </tr>

            <tr>
              <td>Price</td>
              <td class="text-right">
                {{ sub.plan.amount / 100 | currency }}
                <span *ngIf="sub.plan.interval == 'year'">/yr</span>
                <span *ngIf="sub.plan.interval == 'month'">/mo</span>
              </td>
            </tr>
            <tr>
              <td>Start Date</td>
              <td class="text-right">{{ sub.start_date * 1000 | date }}</td>
            </tr>
            <tr *ngIf="sub.cancel_at">
              <td>End Date</td>
              <td class="text-right">{{ sub.cancel_at * 1000 | date }}</td>
            </tr>
            <tr>
              <td>Current Period</td>
              <td class="text-right">
                {{ sub.current_period_start * 1000 | date }} -
                {{ sub.current_period_end * 1000 | date }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-right">
          <div class="btn-group">
            <button
              class="btn btn-danger"
              *ngIf="!sub.cancel_at"
              (click)="cancelSubscription(sub)"
            >
              Cancel Subscription
            </button>
            <button
              class="btn btn-primary"
              *ngIf="sub.cancel_at"
              (click)="renewSubscription(sub)"
            >
              Renew Subscription
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #noSubscription>
    <p>
      <i class="fa fa-exclamation-circle mr-1"></i>
      <ng-container>You do not have a subscription.</ng-container>
    </p>
    <button class="btn btn-primary" (click)="showSubscriptionModal()">
      Subscribe
    </button>
  </ng-template>
</ng-container>
