import * as THREE from "three";
import { ElementRef, Injectable, NgZone, OnDestroy } from "@angular/core";
import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader";
import { GUI } from "three/examples/jsm/libs/dat.gui.module";
import { Asset } from "../models/asset.model";
import { AssetService } from "./asset.service";

@Injectable({
  providedIn: "root",
})
export class ToningService implements OnDestroy {
  private canvas: HTMLCanvasElement;
  private renderer: THREE.WebGLRenderer;
  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;
  private mesh: THREE.Mesh;
  private params = {
    exposure: 2.0,
  };

  private frameId: number = null;

  private width;
  private height;

  constructor(private ngZone: NgZone, private assetService: AssetService) {}

  public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
  }

  public async createScene(
    canvas: ElementRef<HTMLCanvasElement>,
    asset: Asset,
    initialWidth: number,
    initialHeight: number
  ) {
    this.width = initialWidth;
    this.height = initialHeight;
    this.canvas = canvas.nativeElement;

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: false,
      antialias: false,
    });

    this.renderer.toneMapping = THREE.ReinhardToneMapping;
    this.renderer.toneMappingExposure = this.params.exposure;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.scene = new THREE.Scene();
    const aspect = this.width / this.height;

    this.camera = new THREE.OrthographicCamera(-aspect, aspect, 1, -1, 0, 1);

    const exrLoader = new EXRLoader().setDataType(THREE.FloatType);

    const url = this.assetService.outputImageUrl(asset);
    new EXRLoader()
      .setDataType(THREE.FloatType)
      .load(url, (texture, textureData) => {
        // memorial.exr is NPOT

        //console.log( textureData );
        //console.log( texture );

        // EXRLoader sets these default settings
        //texture.generateMipmaps = false;
        //texture.minFilter = LinearFilter;
        //texture.magFilter = LinearFilter;

        const material = new THREE.MeshBasicMaterial({ map: texture });

        const quad = new THREE.PlaneGeometry(
          (1.5 * textureData.width) / textureData.height,
          1.5
        );

        const mesh = new THREE.Mesh(quad, material);

        this.scene.add(mesh);

        this.render();

        const gui = new GUI();

        gui.add(this.params, "exposure", 0, 100, 0.01).onChange(() => {
          this.render();
        });
        gui.open();
      });
  }

  public setSize(width, height) {
    this.width = width;
    this.height = height;
    this.renderer.setSize(width, height);
    this.resize();
  }

  public animate(): void {
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== "loading") {
        this.render();
      } else {
        window.addEventListener("DOMContentLoaded", () => {
          this.render();
        });
      }
    });
  }

  public render(): void {
    this.renderer.toneMappingExposure = this.params.exposure;

    this.renderer.render(this.scene, this.camera);
  }

  public resize(): void {
    const width = this.width;
    const height = this.height;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }
}
