<ng-container>
  <div class="modal-backdrop">
    <div class="modal modal--small">
      <div class="modal__close" (click)="close()">
        <i class="fas fa-times"></i>
      </div>
      <div class="dialog">
        <h3>{{ title }}</h3>

        <p *ngIf="message">{{ message }}</p>

        <div class="spacer"></div>

        <div class="text-right">
          <div class="btn-group">
            <button
              *ngIf="!noCancel"
              class="btn btn-secondary"
              type="button"
              (click)="close()"
            >
              {{ cancelText }}
            </button>

            <button
              class="btn"
              [ngClass]="{ 'btn-danger': danger, 'btn-primary': !danger }"
              (click)="handleConfirm()"
            >
              {{ confirmText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
