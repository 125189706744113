import { Component, Inject, OnInit } from "@angular/core";
import { ModalRef } from "../../modal/modal-ref";
import { MODAL_DATA } from "../../modal/modal.service";

@Component({
  selector: "app-credit-card-modal",
  templateUrl: "./credit-card-modal.component.html",
  styleUrls: ["./credit-card-modal.component.scss"],
})
export class CreditCardModalComponent implements OnInit {
  constructor(
    public modalRef: ModalRef<CreditCardModalComponent>,
    @Inject(MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  handleConfirm(data) {
    this.modalRef.close(data);
  }

  close() {
    this.modalRef.close(false);
  }
}
