import { Subject } from "rxjs";
import { Observable } from "rxjs";

export class ModalRef<T> {
  private _onClosed = new Subject<any>();

  constructor() {}

  /**
   * Close this modal. Optional data to pass on close.
   *
   * @param {*} [result]
   * @memberof ModalRef
   */
  close(result?: any) {
    this._onClosed.next(result);
    this._onClosed.complete();
  }

  /**
   * On closed observable to get on closed optional data.
   *
   * @returns {Observable<any>}
   * @memberof ModalRef
   */
  onClosed(): Observable<any> {
    return this._onClosed.asObservable();
  }
}
