import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PostQueryType } from "src/app/content/post/models/post.model";
import { User } from "../../models/user.model";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-profile-screen",
  templateUrl: "./profile-screen.component.html",
  styleUrls: ["./profile-screen.component.scss"],
})
export class ProfileScreenComponent implements OnInit {
  public user: User;
  public isMe: boolean = false;
  public postQueryType: PostQueryType = PostQueryType.User;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router
  ) {}

  async ngOnInit() {
    const { uuid } = this.route.snapshot.params;

    if (!uuid) {
      this.userService.user$.subscribe((user) => {
        console.log(user);
        this.router.navigate(["/profile/", user.uuid]);
      });
      return;
    }

    this.userService
      .retrieve(uuid)
      .toPromise()
      .then((user) => {
        this.userService
          .me()
          .toPromise()
          .then((me) => {
            if (me && me.id == user.id) {
              this.isMe = true;
              this.postQueryType = PostQueryType.Me;
            }
            this.user = user;
          });
      });
  }
}
