<div class="card">
  <div class="d-flex justify-content-between align-items-center p-1">
    <div class="top d-flex align-items-center">
      <div class="mr-1"><app-avatar [user]="post.owner"></app-avatar></div>
      <div>
        <div class="name">
          {{ post.owner.first_name }} {{ post.owner.last_name }}
        </div>
        <div class="date">{{ post.created_at | date }}</div>
      </div>
    </div>
    <div>
      <app-post-context-menu-button [post]="post"></app-post-context-menu-button>
    </div>
  </div>
  <div class="middle">
    <a [routerLink]="url">
      <img [src]="imageUrl" width="100%" />
    </a>
    <app-post-draft-indicator [post]="post"></app-post-draft-indicator>

  </div>
  <div class="bottom">
    <div class="d-flex justify-content-between align-items-center px-2 pt-1">
      <div>
        <div class="title">{{ post.title }}</div>
      </div>
      <div class="d-flex">
        <app-favorite-button [post]="post"></app-favorite-button>
        <div class="pr-1"></div>
        <app-bookmark-button [post]="post"></app-bookmark-button>
      </div>
    </div>
    <div class="body px-2">
      <p>{{ post.body }}</p>
    </div>
  </div>
</div>
