import { Component, Input, OnInit } from "@angular/core";
import { Post } from "../../../content/post/models/post.model";

@Component({
  selector: "app-post-card",
  templateUrl: "./post-card.component.html",
  styleUrls: ["./post-card.component.scss"],
})
export class PostCardComponent implements OnInit {
  @Input() post: Post;

  constructor() {}

  ngOnInit(): void {}

  get imageUrl() {
    if (this.post.image) {
      return this.post.image;
    }
    return "/assets/placeholders/image_placeholder.gif";
  }

  get url() {
    if (this.post.status == "draft") {
      return `/posts/draft/${this.post.uuid}`;
    }

    return `/posts/${this.post.uuid}`;
  }
}
