import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-post-create-screen",
  templateUrl: "./post-create-screen.component.html",
  styleUrls: ["./post-create-screen.component.scss"],
})
export class PostCreateScreenComponent implements OnInit {
  public uuid: String;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const { uuid } = this.route.snapshot.params;
    this.uuid = uuid;
    console.log(this.uuid);
  }
}
