import { Injectable } from "@angular/core";

interface ToastOptions {
  header: string;
  body: string;
  className?: string;
  delay?: number;
  autohide?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ToastService {
  toasts: any[] = [];

  constructor() {}

  private show(options: ToastOptions) {
    const defaults: ToastOptions = {
      header: "",
      body: "",
      className: "",
      delay: 3000,
      autohide: true,
    };
    this.toasts.push({ ...defaults, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t != toast);
  }

  success(message: string, title: string = "Success") {
    this.show({
      header: title,
      body: message,
      className: "bg-success text-light",
    });
  }

  error(message: string = "A problem occurred.", title: string = "Error") {
    this.show({
      header: title,
      body: message,
      className: "bg-danger text-light",
    });
  }
}
