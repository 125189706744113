import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class LoginFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder, private authService: AuthService) {}

  async buildForm() {
    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required]],
    });
  }

  async login() {
    const { email, password } = this.form.value;

    try {
      await this.authService.login(email, password).toPromise();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
