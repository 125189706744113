<ng-container *ngIf="!provider">
  <div class="d-flex justify-content-around">
    <button class="btn btn-primary" (click)="provider = providers.Stripe">
      Credit Card
    </button>

    <button class="btn btn-primary" (click)="provider = providers.Paypal">
      PayPal
    </button>
  </div>
</ng-container>
<ng-container *ngIf="provider == providers.Stripe">
  <div class="card">
    <form
      novalidate
      (ngSubmit)="addCard()"
      [formGroup]="stripeTest"
      class="p-3"
    >
      <ngx-stripe-card
        [options]="cardOptions"
        [elementsOptions]="elementsOptions"
      ></ngx-stripe-card>
      <hr />
      <div class="text-right">
        <button class="btn btn-secondary" (click)="handleCancel()">
          Cancel
        </button>

        <button class="btn btn-primary" type="submit">
          <i *ngIf="!processing" class="fa fa-plus mr-1"></i>
          <i *ngIf="processing" class="fa fa-spinner fa-spin"></i>
          Add Card
        </button>
      </div>
    </form>
  </div>
</ng-container>
