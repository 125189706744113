import { isNumber } from "util";
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"],
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() count: number;
  @Input() maxPages: number;
  @Input() perPage: number;
  @Input() page: number;

  @Output() pageChange = new EventEmitter<any>();

  pageCount: number = 0;
  pages: number[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this.generatePages();
  }

  /**
   * Check if there is a previous page available.
   *
   * @returns {boolean}
   * @memberof PaginatorComponent
   */
  hasPrevious(): boolean {
    return this.page > 1;
  }

  /**
   * Check if there is a next page available.
   *
   * @returns {boolean}
   * @memberof PaginatorComponent
   */
  hasNext(): boolean {
    return this.page < this.pageCount;
  }

  showFirst(): boolean {
    return this.pages.length && !this.pages.includes(1);
  }

  showLast(): boolean {
    return this.pages.length && !this.pages.includes(this.pageCount);
  }

  /**
   * Select a page number
   *
   * @param {number} page
   * @memberof PaginatorComponent
   */
  selectPage(page: number) {
    if (page == this.page) return;

    if (page <= 0) {
      page = 0;
      return;
    }

    if (page > this.pageCount) {
      page = this.pageCount;
      return;
    }

    this.page = page;
    this.pageChange.emit(this.page);
    this.generatePages();
  }

  /**
   * Generate page link items from the count, per page and active page.
   *
   * @memberof PaginatorComponent
   */
  generatePages() {
    this.pageCount = Math.ceil(this.count / this.perPage);

    if (typeof this.pageCount !== "number") {
      this.pageCount = 0;
    }

    this.pages = [];
    for (
      let i = Math.max(1, this.page - 5);
      i <= Math.min(this.page + 5, this.pageCount);
      i++
    ) {
      this.pages.push(i);
    }
  }
}
