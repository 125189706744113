import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Asset } from "src/app/content/asset/models/asset.model";

@Component({
  selector: "app-asset-preview-screen",
  templateUrl: "./asset-preview-screen.component.html",
  styleUrls: ["./asset-preview-screen.component.scss"],
})
export class AssetPreviewScreenComponent implements OnInit {
  public asset: Asset = null;
  public fileUrl: string = null;

  constructor(private route: ActivatedRoute) {
    this.fileUrl = this.route.snapshot.queryParams.url;
  }

  ngOnInit(): void {}
}
