import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../../common/services/api.service";
import { User } from "../../user/models/user.model";
import { Token } from "src/app/auth/models/token.model";
import { StorageService } from "../../common/services/storage.service";
import { StorageItem } from "../../common/models/storage.model";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private refreshTimeout;

  constructor(
    private api: ApiService,
    private storageService: StorageService,
    private jwt: JwtHelperService
  ) {}

  public getAccessToken(): string {
    return this.storageService.getAccessToken();
  }

  public getRefreshToken(): string {
    return this.storageService.getRefreshToken();
  }

  public saveToken(token: Token): void {
    this.storageService.setItem(StorageItem.ACCESS_TOKEN, token.access);
    this.storageService.setItem(StorageItem.REFRESH_TOKEN, token.refresh);

    this.setRefreshTimer();
  }

  public get accessTokenExpired() {
    const access = this.getAccessToken();
    return this.jwt.isTokenExpired(access);
  }

  public get refreshTokenExpired() {
    const refresh = this.getRefreshToken();
    return this.jwt.isTokenExpired(refresh);
  }

  public async setRefreshTimer() {
    const access = this.getAccessToken();
    const refresh = this.getRefreshToken();

    if (access && refresh) {
      const j = this.jwt.decodeToken(access);
      const expiresAt = j.exp * 1000;
      const now = new Date().getTime();
      const expiresInMs = expiresAt - now;

      if (expiresInMs <= 0) {
        if (!this.refreshTokenExpired) {
          await this.refreshToken().toPromise();
        }
      }

      if (this.refreshTimeout) {
        clearTimeout(this.refreshTimeout);
      }

      this.refreshTimeout = setTimeout(() => {
        this.refreshToken().toPromise();
      }, expiresInMs);
    }
  }

  public login(email, password): Observable<boolean> {
    const data = {
      email,
      password,
    };
    return this.api.post(this.api.url("/auth/token"), data).pipe(
      map((token: Token) => {
        this.saveToken(token);
        return true;
      })
    );
  }

  public logout(shouldRedirect: boolean = true) {
    this.storageService.removeItem(StorageItem.ACCESS_TOKEN);
    this.storageService.removeItem(StorageItem.REFRESH_TOKEN);
    if (shouldRedirect) {
      window.location.href = "/";
    }
  }

  public register(email, password, first_name, last_name): Observable<boolean> {
    const data: any = {
      email,

      password,
    };

    if (first_name) {
      data.first_name = first_name;
    }

    if (last_name) {
      data.last_name = last_name;
    }

    data.notification = {
      email: true,
      push_notification: true,
    };

    return this.api.post(this.api.url("/auth/register"), data, {}, true).pipe(
      map((data) => {
        console.log("data", data);
        return true;
      })
    );
  }

  public refreshToken() {
    const refreshToken = this.getRefreshToken();

    if (!refreshToken) {
      return of(false);
    }

    return this.api
      .post(this.api.url("/auth/token/refresh/"), {
        refresh: refreshToken,
      })
      .pipe(
        map((token: Token) => {
          this.saveToken(token);
          return true;
        })
      );
  }

  public emailAvailable(email: string) {
    return this.api.post(this.api.url("/auth/email/available"), { email }).pipe(
      map((data) => {
        if (data.is_available) {
          return true;
        }
        return false;
      })
    );
  }

  public usernameAvailable(username: string) {
    return this.api
      .post(this.api.url("/auth/username/available"), { username })
      .pipe(
        map((data) => {
          if (data.is_available) {
            return true;
          }
          return false;
        })
      );
  }

  public requestPasswordReset(email: string) {
    return this.api.post(this.api.url("/auth/password/reset"), { email });
  }

  public validatePasswordReset(uuid: string, token: string) {
    return this.api.post(this.api.url("/auth/password/reset/validate"), {
      uuid,
      token,
    });
  }

  public confirmPasswordReset(uuid: string, token: string, password: string) {
    return this.api.post(this.api.url("/auth/password/reset/confirm"), {
      uuid,
      token,
      password,
    });
  }

  public requestEmailChange(email: string) {
    return this.api.post(this.api.url("/auth/email/change"), { email });
  }

  public validateEmailChange(uuid: string, token: string, payload: string) {
    return this.api.post(this.api.url("/auth/email/change/validate"), {
      uuid,
      token,
      payload,
    });
  }

  public confirmEmailChange(uuid: string, token: string, payload: string) {
    return this.api.post(this.api.url("/auth/email/change/confirm"), {
      uuid,
      token,
      payload,
    });
  }

  public confirmEmail(uuid: string, token: string) {
    const data = {
      uuid,
      token,
    };
    return this.api.post(
      this.api.url("/auth/email/confirmation/confirm"),
      data
    );
  }
}
