import { NgModule } from "@angular/core";
import { PostRoutingModule, routedComponents } from "./post-routing.module";
import { SharedModule } from "src/app/shared/shared.module";
import { PostFormComponent } from "./components/post-create-form/post-form.component";
import { PostFormService } from "./services/post-form.service";

@NgModule({
  declarations: [...routedComponents, PostFormComponent],
  imports: [SharedModule, PostRoutingModule],
  providers: [PostFormService],
})
export class PostModule {}
