<div class="row" [formGroup]="form" *ngIf="ready">
  <div class="col-12">
    <div class="form-group">
      <label>Email Address</label>
      <input
        type="text"
        disabled
        class="form-control"
        formControlName="email"
      />

      <small
        >To change your email, please use the
        <a routerLink="/auth/email-change">email change request form</a>.</small
      >
    </div>
  </div>
  <div class="col-12">
    <div class="form-group">
      <label>Username</label>
      <input
        type="text"
        class="form-control"
        formControlName="username"
        oninput="this.value = this.value.toLowerCase()"
      />
      <app-text-error-message
        [textInput]="form.get('username')"
        name="Username"
      ></app-text-error-message>
    </div>
  </div>

  <div class="col-12">
    <div class="form-group">
      <label>First Name</label>
      <input type="text" class="form-control" formControlName="first_name" />
      <app-text-error-message
        [textInput]="form.get('first_name')"
        name="First Name"
      ></app-text-error-message>
    </div>
  </div>
  <div class="col-12">
    <div class="form-group">
      <label>Last Name</label>
      <input type="text" class="form-control" formControlName="last_name" />
      <app-text-error-message
        [textInput]="form.get('last_name')"
        name="Last Name"
      ></app-text-error-message>
    </div>
  </div>

  <div class="col-12">
    <app-image-uploader
      (onImageChange)="handleImageChange($event)"
      [initialValue]="user.image"
      label="Avatar"
      placeholderImage="assets/placeholders/avatar_placeholder.gif"
    ></app-image-uploader>
  </div>

  <div class="col text-right">
    <div class="btn-group">
      <button class="btn btn-outline-secondary" (click)="cancel()">
        Cancel
      </button>
      <button class="btn btn-primary" (click)="save()">Save</button>
    </div>
  </div>
</div>
