import { Injectable } from "@angular/core";
import { StorageItem } from "../models/storage.model";
@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor() {}

  setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: string) {
    let stringData = localStorage.getItem(key) || null;
    return JSON.parse(stringData);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  getAccessToken() {
    const stringData = localStorage.getItem(StorageItem.ACCESS_TOKEN) || null;
    return JSON.parse(stringData);
  }

  getRefreshToken() {
    const stringData = localStorage.getItem(StorageItem.REFRESH_TOKEN) || null;
    return JSON.parse(stringData);
  }
}
