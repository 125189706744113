import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "src/app/shared/services/toast.service";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-email-confirm-screen",
  templateUrl: "./email-confirm-screen.component.html",
  styleUrls: ["./email-confirm-screen.component.scss"],
})
export class EmailConfirmScreenComponent implements OnInit {
  public pending = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    const { uuid, token } = this.route.snapshot.params;

    console.log({ uuid, token });

    this.pending = true;
    try {
      await this.authService.confirmEmail(uuid, token).toPromise();
      this.toastService.success("Thank you for confirming your email");
    } catch (e) {
      console.log(e);
      this.toastService.error();
    }

    this.router.navigate(["/"]);
  }
}
