import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastService } from "src/app/shared/services/toast.service";
import { SettingsFormService } from "../../services/settings-form.service";

@Component({
  selector: "app-settings-form",
  templateUrl: "./settings-form.component.html",
  styleUrls: ["./settings-form.component.scss"],
})
export class SettingsFormComponent implements OnInit {
  public ready: boolean;

  constructor(
    private settingsFormService: SettingsFormService,
    private router: Router,
    private toastService: ToastService
  ) {}

  public get user() {
    return this.settingsFormService.user;
  }

  public get form() {
    return this.settingsFormService.form;
  }

  async ngOnInit() {
    await this.settingsFormService.buildForm();
    this.ready = true;
  }

  async handleImageChange(url: String) {
    this.settingsFormService.updateImage(url);
  }

  cancel() {
    this.router.navigate(["/profile/", this.user.uuid]);
  }

  async save() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    const success = await this.settingsFormService.save();

    if (success) {
      this.toastService.success("Settings saved successfully");
      this.router.navigate(["/profile/", this.user.uuid]);
    }
  }
}
