import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { StorageService } from "./common/services/storage.service";
import { InterceptTokenService } from "./auth/services/intercept-token.service";
import { AuthGuard } from "./auth/guards/auth.guard";

import { SharedModule } from "./shared/shared.module";
import { UserModule } from "./user/user.module";
import { AuthModule } from "./auth/auth.module";
import { PostModule } from "./content/post/post.module";
import { BrowserModule } from "@angular/platform-browser";
import { JwtModule } from "@auth0/angular-jwt";
import { NgxStripeModule } from "ngx-stripe";
import { environment } from "src/environments/environment";
import { AssetModule } from "./content/asset/asset.module";

export function tokenGetter() {
  return localStorage.getItem("jwt_access_token");
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        // allowedDomains: environment.allowedDomains,
      },
    }),
    AppRoutingModule,
    SharedModule,
    PostModule,
    UserModule,
    AuthModule,
    AssetModule,
  ],
  providers: [
    AuthGuard,
    StorageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptTokenService,
      multi: true,
    },
  ],
})
export class AppModule {}
