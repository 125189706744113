<div class="card">
  <a [routerLink]="'/assets/' + asset.uuid">
    <img [src]="asset.thumbnail_image.url" width="100%" />
  </a>

  <div class="p-2">
    <h5>{{ asset.title }}</h5>
    <span
      class="badge text-uppercase"
      [ngClass]="{
        'badge-primary': asset.status != 'complete',
        'badge-success': asset.status == 'complete'
      }"
      >{{ asset.status }}
    </span>
  </div>
</div>
