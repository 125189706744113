<div ngbDropdown>
    <button class="btn btn-icon" id="dropdownBasic1" ngbDropdownToggle><i class="fa fa-ellipsis-v"></i></button>
    <div ngbDropdownMenu>
        <ng-container *ngIf="isMine">
            <button ngbDropdownItem (click)="handleEdit()"><i class="fa fa-edit mr-1"></i>Edit Post</button>
            <hr />
        </ng-container>
        <button ngbDropdownItem><i class="fa fa-heart mr-1"></i>Like Post</button>
        <hr />
        <button ngbDropdownItem><i class="fa fa-bookmark mr-1"></i>Save Post</button>
        <hr />
        <button ngbDropdownItem class="text-danger"><i class="fa fa-flag mr-1"></i>Report Post</button>
    </div>
</div>