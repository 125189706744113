<ng-container *ngIf="brands.includes(brand); else noBrand">
  <i class="fab fa-cc-visa" *ngIf="brand == 'visa'"></i>
  <i class="fab fa-cc-mastercard" *ngIf="brand == 'mastercard'"></i>
  <i class="fab fa-cc-amex" *ngIf="brand == 'amex'"></i>
  <i class="fab fa-cc-discover" *ngIf="brand == 'discover'"></i>
</ng-container>

<ng-template #noBrand>
  <i class="fa fa-credit-card"></i>
</ng-template>
