import { Component, OnInit, Inject } from "@angular/core";
import { ModalRef, MODAL_DATA } from "../../modal";

@Component({
  selector: "app-confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"],
})
export class ConfirmModalComponent implements OnInit {
  public title = "Are you sure?";
  public message = "";
  public confirmText = "Okay";
  public noCancel = false;
  public cancelText = "Cancel";
  public danger = false;

  constructor(
    public modalRef: ModalRef<ConfirmModalComponent>,
    @Inject(MODAL_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data) {
      if (this.data.title) {
        this.title = this.data.title;
      }

      if (this.data.message) {
        this.message = this.data.message;
      }

      if (this.data.confirmText) {
        this.confirmText = this.data.confirmText;
      }

      if (this.data.cancelText) {
        this.cancelText = this.data.cancelText;
      }
      if (this.data.danger) {
        this.danger = true;
      }
      if (this.data.noCancel) {
        this.noCancel = true;
      }
    }
  }

  handleConfirm() {
    this.modalRef.close(true);
  }

  close() {
    this.modalRef.close(false);
  }
}
