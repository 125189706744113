import { FileAsset } from "src/app/media/models/asset.model";
import { User } from "src/app/user/models/user.model";

export enum PostStatus {
  Draft = "draft",
  Published = "published",
}

export enum PostQueryType {
  Public = "public",
  User = "user",
  Me = "me",
}

export interface Post {
  id?: number;
  uuid?: string;
  status?: PostStatus;
  title?: String;
  body?: String;
  language?: String;
  image?: FileAsset;
  tags?: String[];
  owner?: User;
  created_at?: Date;
  updated_at?: Date;
}
