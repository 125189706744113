<div class="text-center">
  <h2>Subscribe to Pro?</h2>
  <p>
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Delectus, tempora!
    Provident veritatis explicabo esse labore unde quo, iste fugit vero adipisci
    at ullam quas quisquam saepe excepturi eos dolor quasi.
  </p>

  <button class="btn btn-primary" (click)="handleSubscribe()">Subscribe</button>
  <hr />
  <a class="btn btn-outline-secondary btn-sm" routerLink="/">Maybe Later</a>
</div>
