<ng-container *ngIf="assets$ | async as data">
  <ng-container *ngIf="data && data.results.length > 0; else createCta">
    <div class="row">
      <div class="col-12 col-md-4 pb-2" *ngFor="let asset of data.results">
        <app-asset-card [asset]="asset"></app-asset-card>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <app-paginator
        [page]="data.page"
        [count]="data.count"
        [perPage]="limit"
        (pageChange)="onPageChange($event)"
      ></app-paginator>
    </div>
  </ng-container>
</ng-container>

<ng-template #createCta>
  <div class="text-center">
    <p>You don't have any assets.</p>
    <a href="/assets/create" class="btn btn-lg btn-primary">
      <i class="fa fa-plus"></i>
      Create One Now</a
    >
  </div>
</ng-template>
