<div class="py-3" *ngIf="ready && !completed && form" [formGroup]="form">
  <div class="form-group">
    <label>Email</label>
    <input type="text" class="form-control" formControlName="email" />
    <app-text-error-message
      [textInput]="form.get('email')"
      name="Email"
    ></app-text-error-message>
  </div>

  <div class="text-right">
    <button class="btn btn-secondary" (click)="submit()">Submit</button>
  </div>
</div>

<div *ngIf="completed">
  <p>
    If an account exists with that email address then a password reset email has
    been sent.
  </p>
</div>

<div *ngIf="confirmationForm" [formGroup]="confirmationForm">
  <div class="form-group">
    <label>New Password</label>
    <input type="password" class="form-control" formControlName="password" />
    <app-text-error-message
      [textInput]="confirmationForm.get('password')"
      name="Password"
    ></app-text-error-message>
  </div>
  <div class="text-right">
    <button class="btn btn-secondary" (click)="submitConfirmation()">
      Save
    </button>
  </div>
</div>
