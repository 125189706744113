import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { PaginatedResponse } from "src/app/common/models/paginated-response.model";
import { Asset } from "src/app/content/asset/models/asset.model";
import { AssetService } from "src/app/content/asset/services/asset.service";

@Component({
  selector: "app-asset-list",
  templateUrl: "./asset-list.component.html",
  styleUrls: ["./asset-list.component.scss"],
})
export class AssetListComponent implements OnInit {
  public assets$: Observable<PaginatedResponse<Asset>>;

  public limit: number = 6;
  public page: number;

  constructor(private assetService: AssetService) {}

  ngOnInit(): void {
    this.query();
  }

  private query(page: number = 1) {
    this.page = page;

    this.assets$ = this.assetService.list(this.page, this.limit);
  }

  onPageChange(page: number) {
    this.query(page);
  }
}
