import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PostCardComponent } from "./components/post-card/post-card.component";
import { AvatarComponent } from "./components/avatar/avatar.component";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FavoriteButtonComponent } from "./components/favorite-button/favorite-button.component";
import { BookmarkButtonComponent } from "./components/bookmark-button/bookmark-button.component";
import { PostListComponent } from "./components/post-list/post-list.component";
import { PostDraftIndicatorComponent } from "./components/post-draft-indicator/post-draft-indicator.component";
import { PostContextMenuButtonComponent } from "./components/post-context-menu-button/post-context-menu-button.component";
import { ImageUploaderComponent } from "./components/image-uploader/image-uploader.component";
import { TextErrorMessageComponent } from "./components/text-error-message/text-error-message.component";
import { BillingFormComponent } from "./components/billing-form/billing-form.component";
import { NgxStripeModule } from "ngx-stripe";
import { environment } from "src/environments/environment";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmModalComponent } from "./components/modals/confirm-modal/confirm-modal.component";
import { CreditCardModalComponent } from "./components/modals/credit-card-modal/credit-card-modal.component";
import { CreditCardIconComponent } from "./components/credit-card-icon/credit-card-icon.component";
import { SubscribeModalComponent } from "./components/modals/subscribe-modal/subscribe-modal.component";
import { TagEditorComponent } from "./components/tag-editor/tag-editor.component";
import { ModalContainer, ModalService, MODAL_DATA } from "./components/modal";
import { TagInputModule } from "ngx-chips";
import { AssetListComponent } from "./components/asset-list/asset-list.component";
import { AssetCardComponent } from "./components/asset-card/asset-card.component";
import { PlacesAutocompleteComponent } from "./components/places-autocomplete/places-autocomplete.component";

export function tokenGetter() {
  return localStorage.getItem("jwt_access_token");
}

@NgModule({
  declarations: [
    PostCardComponent,
    AvatarComponent,
    PaginatorComponent,
    FavoriteButtonComponent,
    BookmarkButtonComponent,
    PostListComponent,
    PostDraftIndicatorComponent,
    PostContextMenuButtonComponent,
    ImageUploaderComponent,
    TextErrorMessageComponent,
    BillingFormComponent,

    // modals
    ModalContainer,
    ConfirmModalComponent,
    CreditCardModalComponent,
    CreditCardIconComponent,
    SubscribeModalComponent,
    TagEditorComponent,
    AssetListComponent,
    AssetCardComponent,
    PlacesAutocompleteComponent,
  ],
  imports: [
    CommonModule,
    // BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    TagInputModule,
    NgxStripeModule.forRoot(environment.stripeKey),
  ],

  exports: [
    // angular
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // vendor
    NgbModule,
    // project
    PostListComponent,
    PostCardComponent,
    AvatarComponent,
    PaginatorComponent,
    FavoriteButtonComponent,
    BookmarkButtonComponent,
    PostDraftIndicatorComponent,
    PostContextMenuButtonComponent,
    ImageUploaderComponent,
    TextErrorMessageComponent,
    BillingFormComponent,

    ModalContainer,
    ConfirmModalComponent,
    SubscribeModalComponent,

    CreditCardIconComponent,
    TagEditorComponent,
    AssetListComponent,
    AssetCardComponent,
    PlacesAutocompleteComponent,
  ],
  providers: [ModalService, { provide: MODAL_DATA, useValue: "MODAL_DATA" }],
})
export class SharedModule {}
