import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ToastService } from "src/app/shared/services/toast.service";
import { User } from "src/app/user/models/user.model";
import { UserService } from "src/app/user/services/user.service";
import { LoginFormService } from "../../services/login-form.service";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent implements OnInit {
  public ready = false;
  public processing = false;

  @Input() forgotPasswordNewTab = false;

  @Output() onSuccess = new EventEmitter<User>();
  @Output() onError = new EventEmitter<void>();

  constructor(
    private loginFormService: LoginFormService,
    private userService: UserService,
    private toastService: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.loginFormService.buildForm();
    this.ready = true;
  }

  public get form() {
    return this.loginFormService.form;
  }

  private get hasSuccessSubscriber() {
    return this.onSuccess.observers.length > 0;
  }

  private get hasErrorSubscriber() {
    return this.onError.observers.length > 0;
  }

  async handleLogin() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.processing = true;

    try {
      const success = await this.loginFormService.login();
      if (success) {
        const user = await this.userService.me().toPromise();
        this.processing = false;

        this.toastService.success(`Welcome back!`);
        if (this.hasSuccessSubscriber) {
          this.onSuccess.emit(user);
        } else {
          this.router.navigate(["/"]);
        }
      } else {
        this.processing = false;

        this.toastService.error("Incorrect login details");
        if (this.hasErrorSubscriber) {
          this.onError.emit();
        }
      }
    } catch (e) {
      this.processing = false;

      console.log(e);
      this.toastService.error("Incorrect login details");

      if (this.hasErrorSubscriber) {
        this.onError.emit();
      }
    }
  }
}
