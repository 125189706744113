import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Asset } from "../../models/asset.model";
import { EngineService } from "../../services/engine.service";

@Component({
  selector: "app-hdr-preview",
  templateUrl: "./hdr-preview.component.html",
  styleUrls: ["./hdr-preview.component.scss"],
})
export class HdrPreviewComponent implements OnInit {
  @Input() fileUrl: string;
  @Input() showControls: boolean = true;

  @ViewChild("container", { static: true })
  public container: ElementRef<HTMLElement>;

  @ViewChild("rendererCanvas", { static: true })
  public rendererCanvas: ElementRef<HTMLCanvasElement>;

  public loading: boolean = true;

  constructor(private engineService: EngineService) {}

  ngOnInit(): void {
    const size = window.screen.width;
    // window.addEventListener("flutterInAppWebViewPlatformReady", (event) => {
    console.log("READY!");
    this.engineService.createScene(
      this.rendererCanvas,
      this.fileUrl,
      size,
      size,
      this.showControls
    );

    this.resize();
    this.engineService.animate();

    window.addEventListener("resize", () => {
      this.resize();
    });

    window.addEventListener(
      "resizeOverride",
      (event: any) => {
        const value = event.detail.value;
        this.engineService.setSize(value, value);
      },
      false
    );

    this.engineService.isReady.subscribe((ready) => {
      this.loading = !ready;
    });
    // });
  }

  resize() {
    const size = this.container.nativeElement.offsetWidth;
    this.engineService.setSize(size, size);
  }
}
