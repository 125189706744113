import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForgotPasswordFormComponent } from "./components/forgot-password-form/forgot-password-form.component";
import { AuthScreenComponent } from "./screens/auth-screen/auth-screen.component";
import { EmailChangeScreenComponent } from "./screens/email-change-screen/email-change-screen.component";
import { EmailConfirmScreenComponent } from "./screens/email-confirm-screen/email-confirm-screen.component";
import { ForgotPasswordScreenComponent } from "./screens/forgot-password-screen/forgot-password-screen.component";
import { LoginScreenComponent } from "./screens/login-screen/login-screen.component";
import { RegisterScreenComponent } from "./screens/register-screen/register-screen.component";
import { SubscribeScreenComponent } from "./screens/subscribe-screen/subscribe-screen.component";

const authRoutes: Routes = [
  {
    path: "",
    component: AuthScreenComponent,
    children: [
      {
        path: "login",
        component: LoginScreenComponent,
      },
      {
        path: "register",
        component: RegisterScreenComponent,
      },
      {
        path: "subscribe",
        component: SubscribeScreenComponent,
      },
      {
        path: "password-reset",
        component: ForgotPasswordScreenComponent,
      },
      {
        path: "password-reset/:uuid/:token",
        component: ForgotPasswordScreenComponent,
      },
      {
        path: "email-change",
        component: EmailChangeScreenComponent,
      },
      {
        path: "email-change/:uuid/:token/:payload",
        component: EmailChangeScreenComponent,
      },
      {
        path: "email-confirm/:uuid/:token",
        component: EmailConfirmScreenComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}

export const routedComponents = [
  AuthScreenComponent,
  LoginScreenComponent,
  RegisterScreenComponent,
  ForgotPasswordScreenComponent,
  EmailChangeScreenComponent,
  SubscribeScreenComponent,
];
