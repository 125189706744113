import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { Post } from "../../models/post.model";
import { PostService } from "../../services/post.service";

@Component({
  selector: "app-post-detail-screen",
  templateUrl: "./post-detail-screen.component.html",
  styleUrls: ["./post-detail-screen.component.scss"],
})
export class PostDetailScreenComponent implements OnInit {
  public post: Post;
  constructor(
    private route: ActivatedRoute,
    private postService: PostService
  ) {}

  ngOnInit(): void {
    const { uuid } = this.route.snapshot.params;
    const { isDraft } = this.route.snapshot.data;

    if (isDraft) {
      this.postService
        .retrieveMine(uuid)
        .toPromise()
        .then((post) => {
          this.post = post;
        });
    } else {
      this.postService
        .retrieve(uuid)
        .toPromise()
        .then((post) => {
          this.post = post;
        });
    }
  }

  get imageUrl(): String {
    if (this.post.image) {
      return this.post.image.url;
    }

    return "/assets/placeholders/image_placeholder.gif";
  }
}
