import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "src/app/user/services/user.service";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class RegisterFormService {
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private authService: AuthService
  ) {}

  async buildForm() {
    this.form = this.fb.group({
      email: ["", [Validators.required, Validators.email]],

      first_name: ["", []],
      last_name: ["", []],
      password: ["", [Validators.required, Validators.minLength(8)]],
    });
  }

  async register() {
    const { email, password, first_name, last_name } = this.form.value;

    const emailAvailable = await this.authService
      .emailAvailable(email)
      .toPromise();
    if (!emailAvailable) {
      this.form.controls.email.setErrors({ unavailable: true });
      return;
    }

    try {
      await this.authService
        .register(email, password, first_name, last_name)
        .toPromise();
      await this.authService.login(email, password).toPromise();
      return await this.userService.me().toPromise();
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
