import * as THREE from "three";
import { ElementRef, Injectable, NgZone, OnDestroy } from "@angular/core";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { GUI } from "three/examples/jsm/libs/dat.gui.module";

import { Asset } from "../models/asset.model";
import { AssetService } from "./asset.service";
import { BehaviorSubject } from "rxjs";

declare const messageChannel: any;

@Injectable({ providedIn: "root" })
export class EngineService implements OnDestroy {
  private canvas: HTMLCanvasElement;
  private renderer: THREE.WebGLRenderer;
  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;
  private controls: OrbitControls;
  private mesh: THREE.Mesh;
  private torusMesh: THREE.Mesh;
  private sphereMesh: THREE.Mesh;
  private boxMesh: THREE.Mesh;
  private frameId: number = null;

  private width;
  private height;

  public isReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public params = {
    roughness: 0.0,
    metalness: 0.5,
    exposure: 1.0,
  };

  public constructor(
    private ngZone: NgZone,
    private assetService: AssetService
  ) {}

  public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
  }

  public setSize(width, height) {
    this.width = width;
    this.height = height;
    this.renderer.setSize(width, height);
    this.resize();
  }

  public async createScene(
    canvas: ElementRef<HTMLCanvasElement>,
    fileUrl: string,
    initialWidth: number,
    initialHeight: number,
    showGui: boolean
  ) {
    this.width = initialWidth;
    this.height = initialHeight;
    this.canvas = canvas.nativeElement;

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      alpha: true,
      antialias: false,
    });

    this.renderer.setSize(this.width, this.height);

    this.renderer.add;

    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1.0;
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    THREE.ShaderChunk.tonemapping_pars_fragment =
      THREE.ShaderChunk.tonemapping_pars_fragment.replace(
        "vec3 CustomToneMapping( vec3 color ) { return color; }",
        `#define Uncharted2Helper( x ) max( ( ( x * ( 0.15 * x + 0.10 * 0.50 ) + 0.20 * 0.02 ) / ( x * ( 0.15 * x + 0.50 ) + 0.20 * 0.30 ) ) - 0.02 / 0.30, vec3( 0.0 ) )
      float toneMappingWhitePoint = 1.0;
      vec3 CustomToneMapping( vec3 color ) {
        color *= toneMappingExposure;
        return saturate( Uncharted2Helper( color ) / Uncharted2Helper( vec3( toneMappingWhitePoint ) ) );
      }`
      );

    // create the scene
    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
      40,
      this.width / this.height,
      1,
      1000
    );

    this.camera.position.set(0, 0, 120);
    this.scene.add(this.camera);

    // controls
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.addEventListener("change", () => {
      this.render();
    });
    this.controls.enableZoom = true;
    this.controls.enablePan = false;
    this.controls.minDistance = 50;
    this.controls.maxDistance = 300;
    this.controls.target.set(0, 0, 0);
    this.controls.update();

    const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    pmremGenerator.compileEquirectangularShader();

    // .setPath("assets/temp/");

    // const gltfLoader = new GLTFLoader().setPath("assets/temp/helmet/");

    // const url = this.assetService.outputImageUrl(asset);
    window.addEventListener("flutterInAppWebViewPlatformReady", (event) => {
      if (globalThis.flutter_inappwebview) {
        globalThis.flutter_inappwebview.callHandler("initializing");
      }
    });
    // if (messageChannel) {
    //   messageChannel.postMessage("HI SILLY GUY");
    // }
    const manager = new THREE.LoadingManager();
    manager.onStart = () => {
      console.log("Loading Started");
      if (globalThis.flutter_inappwebview) {
        globalThis.flutter_inappwebview.callHandler("loadingStart");
      }
    };

    manager.onLoad = () => {
      console.log("Loading complete");
      this.isReady.next(true);
      if (globalThis.flutter_inappwebview) {
        globalThis.flutter_inappwebview.callHandler("ready");
      }
    };

    const exrLoader = new EXRLoader(manager).setDataType(
      THREE.UnsignedByteType
    );

    // const texture = exrLoader.loadAsync(fileUrl);

    exrLoader.load(
      fileUrl,
      (texture) => {
        texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();

        const envMap = pmremGenerator.fromEquirectangular(texture).texture;

        envMap.minFilter = THREE.NearestFilter;
        envMap.magFilter = THREE.NearestFilter;
        envMap.generateMipmaps = false;

        this.scene.background = envMap;
        this.scene.environment = envMap;

        texture.dispose();

        const torusGeometry = new THREE.TorusKnotGeometry(18, 8, 150, 20);
        let torusMaterial = new THREE.MeshStandardMaterial({
          color: 0xffffff,
          metalness: this.params.metalness,
          roughness: this.params.roughness,
        });

        this.torusMesh = new THREE.Mesh(torusGeometry, torusMaterial);
        this.scene.add(this.torusMesh);

        const sphereGeometry = new THREE.SphereGeometry(26, 64, 32);
        let sphereMaterial = new THREE.MeshStandardMaterial({
          color: 0xffffff,
          metalness: this.params.metalness,
          roughness: this.params.roughness,
        });

        this.sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
        this.scene.add(this.sphereMesh);

        const boxGeometry = new THREE.BoxGeometry(24, 24, 32);
        let boxMaterial = new THREE.MeshStandardMaterial({
          color: 0xffffff,
          metalness: this.params.metalness,
          roughness: this.params.roughness,
        });

        this.boxMesh = new THREE.Mesh(boxGeometry, boxMaterial);
        this.scene.add(this.boxMesh);

        this.torusMesh.visible = false;
        this.boxMesh.visible = false;

        this.resize();
        this.render();
      },
      (progress) => {
        const percent = progress.loaded / progress.total;
        if (globalThis.flutter_inappwebview) {
          globalThis.flutter_inappwebview.callHandler(
            "progressHandler",
            percent
          );
        }
      }
    );

    // const [texture, gltf] = await Promise.all([
    //   exrLoader.loadAsync(fileUrl),
    //   // gltfLoader.loadAsync("DamagedHelmet.gltf"),
    // ]).finally(() => {
    //   // this.isReady.next(true);
    // });

    if (showGui) {
      const gui = new GUI();

      gui.add(this.params, "roughness", 0, 1, 0.01).onChange(() => {
        this.render();
      });

      gui.add(this.params, "metalness", 0, 1, 0.01).onChange(() => {
        this.render();
      });
      gui.add(this.params, "exposure", 0, 2, 0.01).onChange(() => {
        this.render();
      });
      gui.open();
    }

    window.addEventListener(
      "updateExposure",
      (event: any) => {
        const value = event.detail.value;
        this.params.exposure = value;

        this.render();
      },
      false
    );

    window.addEventListener(
      "updateMetalness",
      (event: any) => {
        const value = event.detail.value;
        this.params.metalness = value;

        this.render();
      },
      false
    );

    window.addEventListener(
      "updateRoughness",
      (event: any) => {
        const value = event.detail.value;
        this.params.roughness = value;

        this.render();
      },
      false
    );

    window.addEventListener(
      "showTorus",
      () => {
        if (this.torusMesh) {
          this.torusMesh.visible = true;
        }

        if (this.sphereMesh) {
          this.sphereMesh.visible = false;
        }

        if (this.boxMesh) {
          this.boxMesh.visible = false;
        }

        this.render();
      },
      false
    );

    window.addEventListener(
      "showSphere",
      () => {
        if (this.torusMesh) {
          this.torusMesh.visible = false;
        }

        if (this.sphereMesh) {
          this.sphereMesh.visible = true;
        }

        if (this.boxMesh) {
          this.boxMesh.visible = false;
        }

        this.render();
      },
      false
    );

    window.addEventListener(
      "showBox",
      () => {
        if (this.torusMesh) {
          this.torusMesh.visible = false;
        }

        if (this.sphereMesh) {
          this.sphereMesh.visible = false;
        }

        if (this.boxMesh) {
          this.boxMesh.visible = true;
        }

        this.render();
      },
      false
    );
  }

  public animate(): void {
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== "loading") {
        this.render();
      } else {
        window.addEventListener("DOMContentLoaded", () => {
          this.render();
        });
      }
    });
  }

  public render(): void {
    // this.frameId = requestAnimationFrame(() => {
    //   this.render();
    // });

    if (this.torusMesh) {
      this.torusMesh.material.roughness = this.params.roughness;
      this.torusMesh.material.metalness = this.params.metalness;
    }
    if (this.sphereMesh) {
      this.sphereMesh.material.roughness = this.params.roughness;
      this.sphereMesh.material.metalness = this.params.metalness;
    }

    this.renderer.toneMappingExposure = this.params.exposure;
    this.renderer.render(this.scene, this.camera);
  }

  public resize(): void {
    const width = this.width;
    const height = this.height;

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
  }
}
