import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/auth/guards/auth.guard";
import { AssetCreateScreenComponent } from "./screens/asset-create-screen/asset-create-screen.component";
import { AssetDetailScreenComponent } from "./screens/asset-detail-screen/asset-detail-screen.component";
import { AssetListScreenComponent } from "./screens/asset-list-screen/asset-list-screen.component";

const assetRoutes: Routes = [
  {
    path: "",
    component: AssetListScreenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "assets/create",
    component: AssetCreateScreenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "assets/edit/:uuid",
    component: AssetCreateScreenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "assets/:uuid",
    component: AssetDetailScreenComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(assetRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class AssetRoutingModule {}

export const routedComponents = [
  AssetListScreenComponent,
  AssetCreateScreenComponent,
  AssetDetailScreenComponent,
];
