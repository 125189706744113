<div class="row" [formGroup]="form" *ngIf="ready">
  <div class="col">
    <div class="form-group">
      <label>Title</label>
      <input type="text" class="form-control" formControlName="title" />
      <app-text-error-message
        [textInput]="form.get('title')"
        name="Title"
      ></app-text-error-message>
    </div>

    <app-image-uploader
      (onImageChange)="handleImageChange($event)"
      [initialValue]="post.image"
    ></app-image-uploader>

    <div class="form-group">
      <label>Body</label>
      <textarea class="form-control" rows="8" formControlName="body"></textarea>
      <app-text-error-message
        [textInput]="form.get('body')"
        name="Body"
      ></app-text-error-message>
    </div>

    <div class="form-group">
      <label>Tags</label>
      <app-tag-editor
        [tags]="post.tags"
        (onChange)="handleTagsChange($event)"
      ></app-tag-editor>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button
          class="btn btn-outline-secondary"
          (click)="save(false)"
          *ngIf="post.status == 'draft'"
        >
          Save Draft
        </button>
        <button
          class="btn btn-outline-secondary"
          *ngIf="post.status == 'published'"
          (click)="unpublish()"
        >
          Unpublish
        </button>
        <button class="btn btn-danger" *ngIf="post.id" (click)="deletePost()">
          Delete
        </button>
        <button class="btn btn-primary" (click)="save(true)">Publish</button>
      </div>
    </div>
  </div>
</div>
