import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "src/app/shared/services/toast.service";
import { EmailChangeFormService } from "../../services/email-change-form.service";

@Component({
  selector: "app-email-change-form",
  templateUrl: "./email-change-form.component.html",
  styleUrls: ["./email-change-form.component.scss"],
})
export class EmailChangeFormComponent implements OnInit {
  public ready = false;
  public completed = false;

  constructor(
    private formService: EmailChangeFormService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit() {
    const { uuid, token, payload } = this.route.snapshot.params;

    if (uuid && token && payload) {
      const valid = await this.formService.validateEmailChange(
        uuid,
        token,
        payload
      );
      if (valid) {
        const completed = await this.formService.confirmEmailChange(
          uuid,
          token,
          payload
        );
        if (completed) {
          this.toastService.success("Email validated and changed.");
          try {
            // window.open("hdreye://app");
            document.location.href = "hdreye://app";
          } catch (e) {
            this.router.navigate(["/"]);
          }
          return;
        }
        this.toastService.error();
        this.router.navigate(["/"]);
      } else {
        this.toastService.error();
        this.router.navigate(["/"]);
      }
    } else {
      this.formService.buildForm();
      this.ready = true;
    }
  }

  public get form() {
    return this.formService.form;
  }

  public async submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const success = await this.formService.requestEmailChange();
    if (success) {
      this.completed = true;
      this.toastService.success("Password reset email sent.");
    } else {
      this.toastService.error();
    }
  }
}
