import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalService } from "src/app/shared/components/modal";
import { CreditCardModalComponent } from "src/app/shared/components/modals/credit-card-modal/credit-card-modal.component";
import { SubscribeModalComponent } from "src/app/shared/components/modals/subscribe-modal/subscribe-modal.component";

@Component({
  selector: "app-subscribe-screen",
  templateUrl: "./subscribe-screen.component.html",
  styleUrls: ["./subscribe-screen.component.scss"],
})
export class SubscribeScreenComponent implements OnInit {
  constructor(private modalService: ModalService, private router: Router) {}

  ngOnInit(): void {}

  handleSubscribe() {
    this.modalService
      .create(CreditCardModalComponent, { data: {} })
      .onClosed()
      .subscribe((success) => {
        if (!success) {
          return;
        }

        this.modalService
          .create(SubscribeModalComponent, { data: {} })
          .onClosed()
          .subscribe((data) => {
            if (data) {
              this.router.navigate(["/"]);
            }
          });
      });
  }
}
