<ng-container *ngIf="posts$ | async as data">
  <div class="row">
    <div class="col-12 col-md-4 pb-2" *ngFor="let post of data.results">
      <app-post-card [post]="post"></app-post-card>
    </div>
  </div>

  <div class="d-flex justify-content-end">
    <app-paginator
      [page]="data.page"
      [count]="data.count"
      [perPage]="limit"
      (pageChange)="onPageChange($event)"
    ></app-paginator>
  </div>
</ng-container>
