<div class="py-2 px-3">
  <h1 class="text-light"><strong>404</strong> not found</h1>
</div>

<div class="px-3">
  <a routerLink="/">Take me home</a>
</div>

<div class="py-5">
  <img src="assets/eyeball.jpg" width="300" />
</div>
