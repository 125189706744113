import { Component, Inject, OnInit } from "@angular/core";
import { User } from "src/app/user/models/user.model";
import { ModalRef } from "../../../../shared/components/modal/modal-ref";
import { MODAL_DATA } from "../../../../shared/components/modal/modal.service";

@Component({
  selector: "app-auth-modal",
  templateUrl: "./auth-modal.component.html",
  styleUrls: ["./auth-modal.component.scss"],
})
export class AuthModalComponent implements OnInit {
  public showingLogin = false;
  constructor(
    public modalRef: ModalRef<AuthModalComponent>,
    @Inject(MODAL_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  handleSuccess(user: User) {
    this.modalRef.close(user);
  }

  handleError() {
    console.log("error...");
  }

  close() {
    this.modalRef.close(false);
  }
}
