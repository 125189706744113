import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthService } from "../services/auth.service";
import { StorageItem } from "../../common/models/storage.model";
import { StorageService } from "../../common/services/storage.service";
import { JwtHelperService } from "@auth0/angular-jwt";
import { UserService } from "src/app/user/services/user.service";
import { ToastService } from "src/app/shared/services/toast.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private jwtHelperService: JwtHelperService,
    private toastService: ToastService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    try {
      const access_token = this.authService.getAccessToken();
      if (!access_token) {
        this.toastService.error(
          "An account is required to perform this action.",
          "Account required!"
        );
        this.router.navigate(["/auth/register"]);
        return false;
      }
      if (this.jwtHelperService.isTokenExpired(access_token)) {
        await this.authService.refreshToken().toPromise();
      }

      const user = await this.userService.me().toPromise();

      if (user) {
        return true;
      }

      this.router.navigate(["/auth/register"]);
      return false;
    } catch (e) {
      // Could not refresh.
      await this.authService.logout();
      this.router.navigate(["/auth/register"]);
    }
    this.router.navigate(["/auth/register"]);
    return false;
  }
}
