import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FileAsset } from "src/app/media/models/asset.model";
import { MediaService } from "src/app/media/services/media.service";

@Component({
  selector: "app-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.scss"],
})
export class ImageUploaderComponent implements OnInit {
  @Input() label: String = "Image";
  @Input() initialValue: FileAsset;
  @Input() initialValues: FileAsset[] = [];
  @Input() multiple: boolean = false;
  @Input() placeholderImage: string =
    "assets/placeholders/image_placeholder.gif";
  @Output() onImageChange = new EventEmitter<FileAsset>();
  @Output() onImagesChange = new EventEmitter<FileAsset[]>();
  @Output() onImageUploadStart = new EventEmitter<string>();

  @ViewChild("imageInput")
  public fileInputEl: ElementRef;
  public imagePreview: FileAsset;
  public imagePreviews: FileAsset[] = [];
  public processing: boolean = false;

  constructor(private mediaService: MediaService) {}

  ngOnInit(): void {
    if (this.multiple) {
      this.imagePreview = null;
      if (this.initialValues) {
        this.imagePreviews = this.initialValues;
      }
    } else {
      if (this.initialValue) {
        this.imagePreview = this.initialValue;
      } else {
        // this.imagePreview = this.placeholderImage;
      }
    }
  }

  removeImage(index: number) {
    this.imagePreviews.splice(index, 1);
    this.onImagesChange.emit(this.imagePreviews);
  }

  async handleImageChange() {
    let files = this.fileInputEl.nativeElement.files;
    this.processing = true;

    this.onImageUploadStart.emit();

    for (let file of files) {
      if (!file) {
        continue;
      }

      let asset = await this.mediaService.upload(file).toPromise();

      if (this.multiple) {
        this.imagePreviews.push(asset);
        this.onImagesChange.emit(this.imagePreviews);
      } else {
        this.imagePreview = asset;
        this.onImageChange.emit(asset);
      }
    }

    this.processing = false;

    // this.mediaService
    //   .upload(file)
    //   .toPromise()
    //   .then((url) => {
    //     if (this.multiple) {
    //       this.imagePreviews.push(url);
    //       this.onImageChange.emit(url);
    //       this.processing = false;
    //     } else {
    //       this.imagePreview = url;
    //       this.processing = false;
    //       this.onImageChange.emit(url);
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }
}
