import { Component, ViewContainerRef, ViewChild } from "@angular/core";

@Component({
  selector: "modal-container",
  templateUrl: "./modal-container.html",
})
export class ModalContainer {
  @ViewChild("container", { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  constructor() {}
}
