import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastService } from "src/app/shared/services/toast.service";
import { Post, PostStatus } from "../models/post.model";
import { PostService } from "./post.service";

@Injectable()
export class PostFormService {
  public post: Post;
  public form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private postService: PostService,
    private toastService: ToastService
  ) {}

  buildForm(post: Post) {
    this.post = post;
    this.form = this.fb.group({
      title: [
        post.title,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(100),
        ],
      ],
      body: [
        post.body,
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(1000),
        ],
      ],
      image: [post.image],
      tags: [post.tags],
    });
  }

  async retrievePost(uuid: String) {
    const post = await this.postService.retrieveMine(uuid).toPromise();
    this.buildForm(post);
  }

  clearForm() {
    this.buildForm({ status: PostStatus.Draft });
  }

  updateImage(url: String) {
    this.form.patchValue({ image: url });
  }

  updateTags(tags: String[]) {
    this.form.patchValue({ tags: tags });
  }

  async save(publish: boolean = false) {
    let post = this.post;

    const formData = {
      ...this.post,
      ...this.form.value,
      status: publish ? PostStatus.Published : PostStatus.Draft,
    };

    try {
      let updatedPost: Post;

      if (post.id) {
        updatedPost = await this.postService.put(post.id, formData).toPromise();
      } else {
        updatedPost = await this.postService.create(formData).toPromise();
      }

      if (updatedPost) {
        this.post = updatedPost;
        return true;
      }

      return false;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async unpublish() {
    this.post.status = PostStatus.Draft;
    return await this.save();
  }

  async deletePost() {
    try {
      await this.postService.delete(this.post.id).toPromise();
      this.clearForm();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
