<div class="row" *ngIf="post">
  <div class="col-12 col-md-6">
    <div class="image">
      <img [src]="imageUrl" width="100%" />
      <app-post-draft-indicator [post]="post"></app-post-draft-indicator>
      <div class="context-menu-container">
        <app-post-context-menu-button
          [post]="post"
        ></app-post-context-menu-button>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div class="d-flex justify-content-between align-items-center p-1">
      <div class="top d-flex align-items-center">
        <div class="mr-1"><app-avatar [user]="post.owner"></app-avatar></div>
        <div>
          <div class="name">
            {{ post.owner.first_name }} {{ post.owner.last_name }}
          </div>
          <div class="date">{{ post.created_at | date }}</div>
        </div>
      </div>
      <div class="d-flex">
        <app-favorite-button [post]="post"></app-favorite-button>
        <div class="pr-2"></div>
        <app-bookmark-button [post]="post"></app-bookmark-button>
      </div>
    </div>
    <h4 class="pt-2">{{ post.title }}</h4>
    <div>
      <span class="badge badge-primary mr-1" *ngFor="let tag of post.tags">{{
        tag
      }}</span>
    </div>
    <div class="body py-2">
      <p>{{ post.body }}</p>
    </div>
  </div>
</div>
