import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/auth/guards/auth.guard";
import { PostCreateScreenComponent } from "./screens/post-create-screen/post-create-screen.component";
import { PostDetailScreenComponent } from "./screens/post-detail-screen/post-detail-screen.component";
import { PostListScreenComponent } from "./screens/post-list-screen/post-list-screen.component";

const postRoutes: Routes = [
  {
    path: "",
    component: PostListScreenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "posts/create",
    component: PostCreateScreenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "posts/edit/:uuid",
    component: PostCreateScreenComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "posts/draft/:uuid",
    component: PostDetailScreenComponent,
    canActivate: [AuthGuard],
    data: { isDraft: true },
  },
  {
    path: "posts/:uuid",
    component: PostDetailScreenComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(postRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class PostRoutingModule {}

export const routedComponents = [
  PostListScreenComponent,
  PostDetailScreenComponent,
  PostCreateScreenComponent,
];
