import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/services/auth.service";
import { ToastService } from "src/app/shared/services/toast.service";
import { User } from "src/app/user/models/user.model";
import { environment } from "src/environments/environment";
import { RegisterFormService } from "../../services/register-form.service";

@Component({
  selector: "app-register-form",
  templateUrl: "./register-form.component.html",
  styleUrls: ["./register-form.component.scss"],
})
export class RegisterFormComponent implements OnInit {
  public ready = false;
  public processing = false;

  @Output() onSuccess = new EventEmitter<User>();
  @Output() onError = new EventEmitter<void>();

  constructor(
    private router: Router,
    private registerFormService: RegisterFormService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.registerFormService.buildForm();
    this.ready = true;
  }

  public get form() {
    return this.registerFormService.form;
  }

  private get hasSuccessSubscriber() {
    return this.onSuccess.observers.length > 0;
  }

  private get hasErrorSubscriber() {
    return this.onError.observers.length > 0;
  }

  async handleRegister() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }

    this.processing = true;

    try {
      const user = await this.registerFormService.register();
      this.processing = false;

      if (user) {
        this.toastService.success("Welcome!");

        if (this.hasSuccessSubscriber) {
          this.onSuccess.emit(user);
        } else {
          if (environment.allowSubscriptions) {
            this.router.navigate(["/auth/subscribe"]);
          } else {
            this.router.navigate(["/"]);
          }
        }
      } else if (!user) {
        this.toastService.error();
        if (this.hasErrorSubscriber) {
          this.onError.emit();
        }
      } else {
        // when success is void: likely means email/username is unavailable
      }
    } catch (e) {
      this.processing = false;

      this.toastService.error();
      if (this.hasErrorSubscriber) {
        this.onError.emit();
      }
    }
  }
}
