import {
  ApplicationRef,
  EmbeddedViewRef,
  Injector,
  Injectable,
  InjectionToken,
  ComponentRef,
  ComponentFactoryResolver,
} from "@angular/core";
import { Subject, Observable } from "rxjs";

import { ModalRef } from "./modal-ref";
import { ModalInjector } from "./modal-injector";
import { ESCAPE } from "src/app/common/constants/keycodes";

export const MODAL_DATA = new InjectionToken<any>("ModalData");

@Injectable()
export class ModalService {
  private boundKeydown = this.handleKeydown.bind(this);
  private afterClosedSubject = new Subject<any>();
  private modalRef: ModalRef<any>;
  private overlayRef: HTMLElement;

  public modalComponentData = null;

  constructor(
    private _injector: Injector,
    private _appRef: ApplicationRef,
    private _resolver: ComponentFactoryResolver
  ) {}

  /**
       * Open a modal with the passed in component
       *
       * @param {any} componentData
       *
       * @memberOf ModalService
      () */
  open<T>(componentData): ModalRef<T> {
    console.log("Opening modal", componentData);
    if (!this.modalComponentData) this.modalComponentData = componentData;

    document.addEventListener("keydown", this.boundKeydown);

    const modalRef = new ModalRef<T>();

    modalRef.onClosed().subscribe((result) => this.close(result));

    this.modalRef = modalRef;

    return this.modalRef;
  }

  /**
   * Close the current modal.
   *
   * @memberOf ModalService
   */
  close(result?: any) {
    if (this.modalComponentData) {
      this.modalComponentData = null;
    }

    this.afterClosedSubject.next(result);
    this.afterClosedSubject.complete();

    if (this.overlayRef)
      this.overlayRef.parentNode.removeChild(this.overlayRef);

    document.removeEventListener("keydown", this.boundKeydown);
  }

  /**
   * Create a modal with a component and config data.
   *
   * @param {any} component
   * @param {any} config
   * @returns {ModalRef}
   * @memberof ModalService
   */
  create<T>(component: any, config: any = {}): ModalRef<T> {
    // Create the overlay
    console.log("Creating overlay");
    let componentFactory = this._resolver.resolveComponentFactory(component);
    let componentRef: ComponentRef<any>;

    let overlay = this.createOverlay();

    // Create modal ref to make sure data can be passed back
    console.log("Modal ref");
    const modalRef = new ModalRef<T>();

    // Create a new injector with modal ref and config data.
    console.log("Creating injector");
    const injector = this.createInjector(modalRef, config);

    // Create a new component ref from the injector
    console.log("component factory injector", injector, componentFactory);
    componentRef = componentFactory.create(injector);

    // Attach the component host view to the App View.
    console.log("attaching view");
    this._appRef.attachView(componentRef.hostView);

    // Add the Element to the DOM
    console.log("Append child");
    overlay.appendChild(
      (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement
    );

    document.addEventListener("keydown", this.boundKeydown);

    modalRef.onClosed().subscribe((result) => this.close(result));

    this.overlayRef = overlay;
    this.modalRef = modalRef;

    return modalRef;
  }

  /**
   * Handle key down event. If user presses escape key, close the modal.
   *
   * @param {any} event
   * @memberof ModalService
   */
  handleKeydown(event) {
    if (event.keyCode == ESCAPE) {
      this.modalRef.close();
    }
  }

  /**
   * Create the overlay element and attach to the DOM.
   *
   * @private
   * @returns
   * @memberof ModalService
   */
  private createOverlay() {
    let overlay = document.createElement("div");
    overlay.classList.add("modal-overlay");
    document.body.appendChild(overlay);
    return overlay;
  }

  /**
   * Create the injector for the modal component.
   *
   * @private
   * @param {any} modalRef
   * @param {any} config
   * @returns
   * @memberof ModalService
   */
  private createInjector(modalRef, config) {
    const injectionTokens = new WeakMap();

    injectionTokens.set(ModalRef, modalRef);
    injectionTokens.set(MODAL_DATA, config.data);

    console.log("Return new modal injector", injectionTokens);
    return new ModalInjector(this._injector, injectionTokens);
  }
}
