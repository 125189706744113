import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-text-error-message",
  templateUrl: "./text-error-message.component.html",
  styleUrls: ["./text-error-message.component.scss"],
})
export class TextErrorMessageComponent implements OnInit {
  @Input() name: String;
  @Input() textInput: any;

  public debug = false;

  constructor() {}

  ngOnInit(): void {}
}
