<div class="py-3" *ngIf="ready" [formGroup]="form">
  <!-- <div class="row">
    <div class="col">
      <div class="form-group">
        <label>First Name</label>
        <input type="text" class="form-control" formControlName="first_name" />
        <app-text-error-message
          [textInput]="form.get('first_name')"
          name="First name"
        ></app-text-error-message>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Last Name</label>
        <input type="text" class="form-control" formControlName="last_name" />
        <app-text-error-message
          [textInput]="form.get('last_name')"
          name="Last name"
        ></app-text-error-message>
      </div>
    </div>
  </div> -->

  <div class="form-group">
    <label>Email</label>
    <input
      type="email"
      name="email"
      class="form-control"
      formControlName="email"
      autocomplete="email"
      [disabled]="processing"
    />
    <app-text-error-message
      [textInput]="form.get('email')"
      name="Email"
    ></app-text-error-message>
  </div>

  <div class="form-group">
    <label>Password</label>
    <input
      type="password"
      class="form-control"
      formControlName="password"
      [disabled]="processing"
    />
    <app-text-error-message
      [textInput]="form.get('password')"
      name="Password"
    ></app-text-error-message>
  </div>
  <div class="d-flex justify-content-between">
    <small class="pr-5">
      By create an account, you are agreeing to our
      <a routerLink="/terms" class="text-primary" target="_blank"
        >Terms & Conditions</a
      >.
    </small>
    <button class="btn btn-primary" (click)="handleRegister()">
      <i class="fa fa-spin fa-spinner mr-1" *ngIf="processing"></i>Register
    </button>
  </div>
</div>
